/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
import React, { useEffect, useContext, useState, useRef } from "react";
import { RiInformation2Fill } from "react-icons/ri";
import axios from "axios";
import UserContext from "../UserContext/UserContext";
import "./TransAction.css";
import Cookies from "js-cookie";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Success from "../Success/Success";
export default function TransAction(props) {
  const refe = useRef(null);
  const { load, url, user, login } = useContext(UserContext);
  const [trans, setTrans] = useState({ payment_methods: [], supporters: [] });
  const [opt, setOpt] = useState([]);
  const [href, setHref] = useState("#");
  const [variz, setVariz] = useState([]);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [tx, setTx] = useState("");
  const [how, setHow] = useState("us");
  const [code, setCode] = useState("");
  const [perLim, setPerLim] = useState([]);
  const [online, setOnline] = useState("");
  const [prAfter, setPrAfter] = useState(props.allData.price);
  const [gavanin, setGavanin] = useState(false);
  const [shabake, setShab] = useState([]);
  const [coupons, setCoupon] = useState("");
  const [shValue, setShabValue] = useState(["", ""]);
  // const [topChangePrice , setTopChangePrice]= useState(false)
  useEffect(() => {
    if (value == 1 || shValue[1] !== "") {
      refe.current.scrollIntoView({ block: "center" });
    }
  }, [value, shValue[1]]);

  useEffect(() => {
    if (online === 3) {
      NotificationManager.info(
        'به دلیل کارمزد، 5% به مبلغ پرداختی شما اضافه شده است.',
        'افزایش مبلغ',
        4000
      );
    }
  }, [online]);
  const messages = () => {
    return (
      <ul ref={refe} className="messageCon">
        {Object.keys(props.messages).map((d) => {
          return (
            <p key={props.messages[d].text}>
              {" "}
              <RiInformation2Fill
                className="information"
                color="greenyellow"
              />{" "}
              {props.messages[d].text}.{" "}
            </p>
          );
        })}
      </ul>
    );
  };
  const ref = useRef("input");
  const ref1 = useRef("btn");
  useEffect(() => {
    load(true);
    axios
      .get(url + "api/v1/panel/transaction", {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setTrans(res.data);
        console.log("trans", res.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);
  const translator = (d) => {
    if (d === "Offline") {
      return "آفلاین";
    } else if (d === "Online") {
      return "آنلاین";
    } else if (d === "wallet_address") {
      return "آدرس متنی ولت";
    } else if (d === "qr-code") {
      return "تصویر QR CODE";
    } else if (d === "CoinsBuy") {
      return "درگاه آنلاین COINS-BUY";
    }
  };
  const method = () => {
    return (
      <fieldset>
        <legend> انتخاب نوع پرداخت </legend>
        {trans.payment_methods.map((d) => {
          if (d.status === 1) {
            return (
              <div key={d.id} className="form-control-radio">
                <input
                  onChange={(e) => {
                    setVariz([d.options[0].value, d.options[0].id]);
                    if (d.id === 2) {
                      setOnline(2);
                      setValue(e.target.value);
                      setOpt([]);
                      setShab(trans.currency);
                    } else if (d.id === 1) {
                      load(true);
                      axios
                        .post(
                          url + "api/v1/auth/me",
                          {},
                          {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          }
                        )
                        .then((resp) => {
                          console.log(resp.data);
                          load(false);
                          login({
                            dataes: resp.data,
                            at: Cookies.get("token"),
                          });
                        })
                        .catch((err) => {
                          if (err) {
                            load(false);
                            login({ dataes: null, at: "", status: false });
                          }
                        });
                      setOnline(1);
                      setValue(e.target.value);
                      setShab([]);
                      setShabValue(["", ""]);
                    } else if (d.id === 3) {
                      setOnline(3);
                      setValue(e.target.value);
                      setShab([]);
                      // setTopChangePrice(true) //new code for top change price

                      setShabValue(["", ""]);
                    }
                  }}
                  className="radio"
                  value={d.id}
                  name="method"
                  type="radio"
                />
                <label> {d.display_name} </label>
              </div>
            );
          }
        })}
      </fieldset>
    );
  };
  const options = () => {
    if (opt.length > 0) {
      return (
        <fieldset>
          <legend> انتخاب نوع واریز </legend>
          {opt.map((d) => {
            return (
              <div className="form-control-radio">
                <input
                  onChange={() => {
                    setVariz([d.value, d.id]);
                  }}
                  className="radio"
                  value={d.id}
                  name="variz"
                  type="radio"
                />
                <label> {translator(d.name)} </label>
              </div>
            );
          })}
        </fieldset>
      );
    }
  };
  // const varizShow = ()=>{
  //     console.log(variz[0]);
  //   if(variz.length !== 0){

  //     if(variz[1] === 2){
  //         return <div className='varizCon'>
  //             <div className='add'> تصویر QR-CODE ولت : </div>
  //             <div className='backQr'>
  //                <QRCode className='qrcode' style={{width:"40%" , height:"40%" , padding:"20px"}} value={variz[0]}  />
  //             </div>

  //         </div>
  //       }else if(variz[1] === 1){
  //         return <div className='varizCon'>
  //             <div className='add'> آدرس ولت : </div>
  //             <div className='wallet'> {variz[0]}  <IoIosCopy onClick={()=>{
  //                 navigator.clipboard.writeText(variz[0])
  //                 NotificationManager.success("آدرس ولت کپی شد" , "" ,3000)
  //             }}/> </div>
  //         </div>
  //       }
  //   }
  // }
  const transBtn = () => {
    if (value == 1 || value == 3 || shValue[1] !== "") {
      return (
        <>
          <button
            onClick={() => {
              setShow(true);
            }}
            className="orderBtn"
          >
            {" "}
            تکمیل خرید{" "}
          </button>
        </>
      );
    }
  };

  const check = () => {
    if (how === "us") {
      return true;
    } else {
      return false;
    }
  };
  const howCreate = () => {
    return (
      <fieldset>
        <legend> نحوه ایجاد حساب </legend>
        <div className="form-control-radio">
          <input
            onChange={(e) => {
              setHow(e.target.value);
            }}
            className="radio"
            checked={check()}
            value="us"
            name="howCreate"
            type="radio"
          />
          <label> توسط فنفیکس </label>
        </div>
        <div className="form-control-radio">
          <input
            onChange={(e) => {
              setHow(e.target.value);
            }}
            className="radio"
            value="user"
            name="howCreate"
            type="radio"
          />
          <label> توسط خودم </label>
        </div>
      </fieldset>
    );
  };
  const coupon = (l, c) => {
    let price = props.allData.price;
    let percent = c / 100;
    if (props.gift) {
      return (
        <>
          <div
            style={{
              textDecoration: "line-through",
              color: "red",
              fontSize: "17px",
            }}
          >
            {Math.round((price * 5) / 3)} $
          </div>
          <div style={{ color: "yellowgreen" }}> {price} $</div>
        </>
      );
    } else {
      if (perLim.length === 0) {
        return price + " " + "$";
      } else {
        if (percent * price > l) {
          return price - l;
        } else {
          return (
            <>
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                  fontSize: "17px",
                }}
              >
                {price} $
              </div>
              <div
                style={{
                  color: "yellowgreen",
                }}
              >
                {" "}
                {price - percent * price} $
              </div>
            </>
          );
        }
      }
    }
  };
  const inModal = () => {
    if (online === 2) {
      return (
        <div className="w515">
          <div className="p10 bB"> انتقال به درگاه پرداخت </div>
          <ol className="olOn">
            <li> حتما دقت نمایید که ارز واریزی {shValue[1]} باشد </li>
            {shValue[0] == 2145 ? (
              <li>
                {" "}
                توجه نمایید که شبکه انتقال رمز ارز را TRC20 انتخاب نمایید{" "}
              </li>
            ) : null}
            <li>
              {" "}
              اطمینان حاصل نمایید که مبلغ واریزی (بعد از احتساب کمیسیون و ...)
              دقیقا برابر با مبلغ {"(( " + prAfter + " $" + " ))"} باشد . اگر
              مبلغ واریزی کمتر باشد به کیف پول فنفیکس شما واریز خواهد شد{" "}
            </li>

            <li>
              {" "}
              در صورت پرداخت مبلغی بیش از مبلغ تعیین شده ، خرید انجام شده و
              مابقی مبلغ واریزی به کیف پول شما واریز خواهد شد{" "}
            </li>

            <li>
              {" "}
              در وارد کردن آدرس کیف پول دقت لازم را داشته باشید ، مبلغ واریز شده
              به غیر از کیف پول تعیین شده توسط درگاه غیر قابل بازگشت می باشد{" "}
            </li>
            <li style={{ color: "red" }}>
              {" "}
              توجه داشته باشید که واریزی های کمتر از 15 $ در شبکه ترون قابل
              پیگیری نمی باشد.{" "}
            </li>
          </ol>
          <div className="mt tar">
            <input
              className="radio mipaziram"
              onChange={(e) => {
                setGavanin(e.target.checked);
              }}
              name="gavanin"
              type="checkbox"
            />
            <label> قوانین و مقررات را خوانده و می پذیرم </label>
          </div>
          <a
            onClick={() => {
              console.log(coupons);
              load(true);
              axios
                .post(
                  url + "api/v1/panel/coupon/used",
                  { coupon: coupons },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  console.log(res.data);
                  setCoupon("");
                })
                .catch((err) => {
                  load(false);
                });
              props.pageChange("تاریخچه تراکنش ها");
            }}
            className="href"
            href={href}
            target="_blank"
          >
            <button disabled={!gavanin} className="orderBtn1">
              {" "}
              انتقال به درگاه{" "}
            </button>
          </a>
        </div>
      );
    } else if (online === 1) {
      return (
        <div className="orderSuc">
          <div className="p10"> </div>
          {/* <input className='form-control-select' value={tx} onChange={(e)=>{setTx(e.target.value)}}/>   */}
          {+user.dataes.wallet + 1 < prAfter ? (
            <>
              <div style={{ padding: "10px", borderBottom: "1px solid" }}>
                {" "}
                مبلغ موجود در کیف پول شما : {user.dataes.wallet + " $"}
              </div>
              <div style={{ padding: "10px", borderBottom: "1px solid" }}>
                {" "}
                مبلغ حساب درخواستی شما : {prAfter + " $"}
              </div>
              <button disabled className="orderBtn1">
                {" "}
                مبلغ کیف پول شما کافی نیست{" "}
              </button>
            </>
          ) : (
            <>
              <div style={{ padding: "10px", borderBottom: "1px solid" }}>
                {" "}
                مبلغ موجود در کیف پول شما : {user.dataes.wallet + " $"}
              </div>
              <div style={{ padding: "10px", borderBottom: "1px solid" }}>
                {" "}
                مبلغ حساب درخواستی شما : {prAfter + " $"}
              </div>
              <button
                onClick={() => {
                  load(true);
                  let dataToSend = {
                    delivery_type: how,
                    txid: tx,
                    ...props.allData,
                  };
                  axios
                    .post(url + "api/v1/panel/order/store", dataToSend, {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    })
                    .then((res) => {
                      let lastData = {
                        coupon_code: coupons,
                        order_id: res.data.data.id,
                        gateway: value,
                      };

                      axios
                        .post(
                          url + "api/v1/panel/transactions/store",
                          lastData,
                          {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          }
                        )
                        .then(() => {
                          load(true);
                          axios
                            .post(
                              url + "api/v1/panel/coupon/used",
                              { coupon: coupons },
                              {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              setCoupon("");
                            })
                            .catch((err) => {
                              console.log(err);
                              load(false);
                            });
                          NotificationManager.success(
                            "می توانید بخش تراکنش ها وضعیت سفارش خود را مشاهده و پیگیری نمایید",
                            "سفارش شما با موفقیت ثبت شد"
                          );
                          props.pageChange("تاریخچه تراکنش ها");
                        })
                        .catch((err) => {
                          load(false);
                          console.log(err);
                        });
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                }}
                className="orderBtn1"
              >
                {" "}
                ثبت تراکنش با کیف پول{" "}
              </button>
            </>
          )}
        </div>
      );
    } else if (online === 3) {
      return (
        <div className="w515">
          <div className="p10 bB"> انتقال به درگاه پرداخت </div>
          <div className="olOn">
            <li>
              {" "}
              برای انتقال به درگاه آنلاین Top Change بر گزینه انتقال به درگاه را
              بزنید
            </li>
            <li style={{ color: "blue" }}>
              {" "}
              <a href="https://topchange1.zendesk.com/hc/fa" target="_blank">
                جهت مشاهده آموزش کامل نحوه ثبت نام و واریز کلیک نمایید
              </a>{" "}
            </li>
          </div>
          <div className="mt tar">
            <input
              className="radio mipaziram"
              onChange={(e) => {
                setGavanin(e.target.checked);
              }}
              name="gavanin"
              type="checkbox"
            />
            <label> قوانین و مقررات را خوانده و می پذیرم </label>
          </div>
          <a
            onClick={() => {
              load(true);
              let dataToSend = { delivery_type: how, ...props.allData };
              axios
                .post(url + "api/v1/panel/order/store", dataToSend, {
                  headers: { Authorization: `bearer ${Cookies.get("token")}` },
                })
                .then((resp) => {
                  let lastData = {
                    coupon_code: coupons,
                    order_id: resp.data.data.id,
                    gateway: value,
                  };
                  axios
                    .post(url + "api/v1/panel/transactions/store", lastData, {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    })
                    .then((res) => {
                      const link = document.createElement("a");
                      link.href = res.data.url; // Set href to the URL from the response
                      link.target = "_blank"; // Open in a new tab
                      document.body.appendChild(link); // Append link to the document
                      link.click(); // Trigger the click to open the URL
                      document.body.removeChild(link);

                      load(true);
                      axios
                        .post(
                          url + "api/v1/panel/coupon/used",
                          { coupon: coupons },
                          {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          }
                        )
                        .then((res) => {
                          load(false);
                          console.log(res.data);
                          setCoupon("");
                        })
                        .catch((err) => {
                          load(false);
                        });
                      props.pageChange("تاریخچه تراکنش ها");
                    })
                    .catch((err) => {
                      load(false);
                      NotificationManager.error(
                        "مشکل در سرور بعدا دوباره امتحان نمایید",
                        "",
                        4000
                      );
                      console.log(err);
                    });
                })
                .catch((err) => {
                  NotificationManager.error(
                    "",
                    "آیتم انتخاب شده در دسترس نیست",
                    4000
                  );
                  console.log(err);
                  load(false);
                });
            }}
            className="href"
            target="_blank"
          >
            <button disabled={!gavanin} className="orderBtn1">
              {" "}
              انتقال به درگاه{" "}
            </button>
          </a>
        </div>
      );
    }
  };
  const shabakeShow = () => {
    if (shabake.length !== 0) {
      return (
        <fieldset className="c1">
          <legend>
            {" "}
            درگاه آنلاین <i>coinsbuy</i>{" "}
          </legend>
          <select
            className="select_input"
            value={shValue[0]}
            name="department"
            onChange={(e) => {
              if (e.currentTarget.value !== "") {
                setShabValue([
                  e.target.value,
                  e.target.selectedOptions[0].innerText,
                ]);
                load(true);
                let dataToSend = { delivery_type: how, ...props.allData };
                axios
                  .post(url + "api/v1/panel/order/store", dataToSend, {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  })
                  .then((res) => {
                    let lastData = {
                      coupon_code: coupons,
                      order_id: res.data.data.id,
                      gateway: value,
                      currency: e.target.value,
                    };
                    axios
                      .post(url + "api/v1/panel/transactions/store", lastData, {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);
                        setHref(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        NotificationManager.error(
                          "مشکل در سرور بعدا دوباره امتحان نمایید",
                          "",
                          4000
                        );
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    if (err.response?.status === 429) {
                      NotificationManager.warning(
                        "",
                        "حداقل فاصله زمانی برای ایجاد تراکنش یک دقیقه می باشد",
                        4000
                      );
                    } else {
                      NotificationManager.error(
                        "",
                        "آیتم انتخاب شده در دسترس نیست",
                        4000
                      );
                    }
                    console.log(err);
                    load(false);
                  });
              } else {
                setShabValue(["", ""]);
              }
            }}
          >
            <option value=""> انتخاب کنید </option>
            {shabake.map((d) => {
              return (
                <option key={d.currency_id} value={d.currency_id}>
                  {" "}
                  {d.name + " | " + d.symbol}{" "}
                </option>
              );
            })}
          </select>
        </fieldset>
      );
    }
  };
  const lastRender = () => {
    if (window.innerWidth > 1200) {
      return (
        <>
          <Success
            show={show}
            name="transAction"
            closeModal={() => {
              setShow(false);
              setGavanin(false);
            }}
          >
            {inModal()}
          </Success>
          <NotificationContainer />
          <div className="app-header-navigation">
            <div className="tabs">
              <a href className="active">
                شرکت در چالش ها / تکمیل خرید /
              </a>
            </div>
            {/* <div style={{padding:"10px",border:"2px solid red" , 
                borderRadius:"5px",margin:"15px",background:"#f2090929"}}>
                همراهان گرامی، در نظر داشته باشید به دلیل بلوکه شدن پرداخت‌های انجام شده 
                از طریق صرافی نوبیتکس، لطفا 
                از این طریق پرداختی انجام ندهید.
                </div> */}
            <div className="row m10">
              <div className="col-xl-5 ">
                <div className="orderAmount">
                  <div style={{ padding: "5px" }}>کل مبلغ پرداختی</div>
                  <h4 className="bB p5"> {coupon(perLim[0], perLim[1])} </h4>
                  <div className="orderDetail bb">
                    <div className="fl"> نوع حساب </div>
                    <div> {props.allData.serverName}</div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> حساب </div>
                    <div> {props.allData.prodName} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> بروکر </div>
                    <div> {props.allData.brokerName} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> پلتفرم </div>
                    <div> {props.allData.platform.toUpperCase()} </div>
                  </div>

                  <div className="orderDetail bb">
                    <div className="fl"> سرور </div>
                    <div> {props.allData.server} </div>
                  </div>
                  {props.gift === false && (
                    <div className="orderDetail bb">
                      <button
                        ref={ref1}
                        onClick={() => {
                          let data = {
                            coupon_code: code,
                            product_id: props.allData.product_id,
                          };

                          if (code === "") {
                            NotificationManager.error(
                              "کد تخفیف وارد نشده",
                              "کد تخفیف اعمال نشد",
                              4000
                            );
                          } else {
                            load(true);
                            axios
                              .post(url + "api/v1/panel/coupon/check", data, {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                if (res.data.error) {
                                  if (
                                    res.data.error === "coupon has been expired"
                                  ) {
                                    NotificationManager.error(
                                      "کد تخفیف منقضی شده",
                                      "کد تخفیف اعمال نشد",
                                      4000
                                    );
                                  } else if (
                                    res.data.error ===
                                    "This Coupon is not for this product"
                                  ) {
                                    NotificationManager.error(
                                      "کد تخفیف برای این محصول نمی باشد",
                                      "کد تخفیف اعمال نشد",
                                      4000
                                    );
                                  }
                                } else {
                                  setCoupon(code);
                                  NotificationManager.success(
                                    res.data.coupon_percent +
                                      " درصد از مبلغ کل کاسته شد تا مبلغ" +
                                      " " +
                                      res.data.coupon_limit +
                                      "$",
                                    "کد تخفیف اعمال شد",
                                    4000
                                  );
                                  setShabValue(["", ""]);

                                  setPerLim([
                                    res.data.coupon_limit,
                                    res.data.coupon_percent,
                                  ]);
                                  ref.current.setAttribute("disabled", true);
                                  ref1.current.setAttribute("disabled", true);
                                  ref1.current.innerText = "اعمال شد";
                                  let price = props.allData.price;
                                  let percent = res.data.coupon_percent / 100;
                                  if (percent * price > res.data.coupon_limit) {
                                    setPrAfter(price - res.data.coupon_limit);
                                  } else {
                                    setPrAfter(price - percent * price);
                                  }
                                }
                              })
                              .catch((err) => {
                                load(false);
                                NotificationManager.error(
                                  "کد تخفیف صحیح نمی باشد",
                                  "کد تخفیف اعمال نشد",
                                  4000
                                );
                                console.log(err);
                              });
                          }
                        }}
                        className="fl codeBtn "
                      >
                        {" "}
                        تایید کد تخفیف
                      </button>
                      <input
                        ref={ref}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                        value={code}
                        className="codeT"
                      />
                    </div>
                  )}
                  {/* <div className='orderDetail '>
                     <div className='fl'> قیمت </div>
                     <div>  {prAfter+" $"} </div>
                 </div> */}
                  <div className="orderDetail ">
                    <div className="fl"> قیمت </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {online === 3 ? (
                        <>
                          <div
                            style={{
                              textDecoration: "line-through",
                              color: "red",
                              marginBottom: "5px",
                            }}
                          >
                            {prAfter + " $"}
                          </div>
                          <div style={{ color: "yellowgreen" }}>
                            {Math.round(prAfter * 1.05 * 100) / 100 + " $"}
                          </div>
                        </>
                      ) : (
                        <>{prAfter + " $"}</>
                      )}
                    </div>
                  </div>
                </div>
                {transBtn()}
                {messages()}
              </div>
              <div className="col-xl-7 pr0">
                <div>
                  <div className="orderChoose">
                    <h6 className="bb"> مشخصات پرداخت خود را انتخاب نمایید</h6>
                    {howCreate()}
                    {method()}
                    {options()}
                    {/* {varizShow()} */}
                    {shabakeShow()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Success
            show={show}
            name="transAction"
            closeModal={() => {
              setShow(false);
              setGavanin(false);
            }}
          >
            {inModal()}
          </Success>
          <NotificationContainer />
          <div className="app-header-navigation">
            <div className="tabs">
              <a href className="active">
                شرکت در چالش ها / تکمیل خرید /
              </a>
            </div>
            <div className="row m10">
              <div className="col-xl-7 pr0">
                <div>
                  <div className="orderChoose">
                    <h6 className="bb"> مشخصات پرداخت خود را انتخاب نمایید</h6>
                    {howCreate()}
                    {method()}
                    {options()}
                    {/* {varizShow()} */}
                    {shabakeShow()}
                  </div>
                </div>
              </div>
              <div className="col-xl-5 ">
                <div className="orderAmount">
                  <div style={{ padding: "5px" }}>کل مبلغ پرداختی</div>
                  <h4 className="bB p5"> {coupon(perLim[0], perLim[1])} </h4>
                  <div className="orderDetail bb">
                    <div className="fl"> نوع حساب </div>
                    <div> {props.allData.serverName}</div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> حساب </div>
                    <div> {props.allData.productPrice} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> بروکر </div>
                    <div> {props.allData.brokerName} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> پلتفرم </div>
                    <div> {props.allData.platform.toUpperCase()} </div>
                  </div>

                  <div className="orderDetail bb">
                    <div className="fl"> سرور </div>
                    <div> {props.allData.server} </div>
                  </div>
                  {props.gift === false && (
                    <div className="orderDetail bb">
                      <button
                        ref={ref1}
                        onClick={() => {
                          let data = {
                            coupon_code: code,
                            product_id: props.allData.product_id,
                          };

                          if (code === "") {
                            NotificationManager.error(
                              "کد تخفیف وارد نشده",
                              "کد تخفیف اعمال نشد",
                              4000
                            );
                          } else {
                            load(true);
                            axios
                              .post(url + "api/v1/panel/coupon/check", data, {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                if (res.data.error) {
                                  if (
                                    res.data.error === "coupon has been expired"
                                  ) {
                                    NotificationManager.error(
                                      "کد تخفیف منقضی شده",
                                      "کد تخفیف اعمال نشد",
                                      4000
                                    );
                                  } else if (
                                    res.data.error ===
                                    "This Coupon is not for this product"
                                  ) {
                                    NotificationManager.error(
                                      "کد تخفیف برای این محصول نمی باشد",
                                      "کد تخفیف اعمال نشد",
                                      4000
                                    );
                                  }
                                } else {
                                  setCoupon(code);
                                  NotificationManager.success(
                                    res.data.coupon_percent +
                                      " درصد از مبلغ کل کاسته شد تا مبلغ" +
                                      " " +
                                      res.data.coupon_limit +
                                      "$",
                                    "کد تخفیف اعمال شد",
                                    4000
                                  );
                                  setShabValue(["", ""]);

                                  setPerLim([
                                    res.data.coupon_limit,
                                    res.data.coupon_percent,
                                  ]);
                                  ref.current.setAttribute("disabled", true);
                                  ref1.current.setAttribute("disabled", true);
                                  ref1.current.innerText = "اعمال شد";
                                  let price = props.allData.price;
                                  let percent = res.data.coupon_percent / 100;
                                  if (percent * price > res.data.coupon_limit) {
                                    setPrAfter(price - res.data.coupon_limit);
                                  } else {
                                    setPrAfter(price - percent * price);
                                  }
                                }
                              })
                              .catch((err) => {
                                load(false);
                                NotificationManager.error(
                                  "کد تخفیف صحیح نمی باشد",
                                  "کد تخفیف اعمال نشد",
                                  4000
                                );
                                console.log(err);
                              });
                          }
                        }}
                        className="fl codeBtn "
                      >
                        {" "}
                        تایید کد تخفیف
                      </button>
                      <input
                        ref={ref}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                        value={code}
                        className="codeT"
                      />
                    </div>
                  )}
                  {/* <div className='orderDetail '>
                        <div className='fl'> قیمت </div>
                        <div>  {prAfter+" $"} </div>
                    </div> */}
                  <div className="orderDetail ">
                    <div className="fl"> قیمت </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {online === 3 ? (
                        <>
                          <div
                            style={{
                              textDecoration: "line-through",
                              color: "red",
                              marginBottom: "5px",
                            }}
                          >
                            {prAfter + " $"}
                          </div>
                          <div style={{ color: "yellowgreen" }}>
                            {Math.round(prAfter * 1.05 * 100) / 100 + " $"}
                          </div>
                        </>
                      ) : (
                        <>{prAfter + " $"}</>
                      )}
                    </div>
                  </div>
                </div>
                {transBtn()}
                {messages()}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <>{lastRender()}</>;
}
