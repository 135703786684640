/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import UserContext from "../UserContext/UserContext";
import Success from "../Success/Success";
export default function SupportPardakht() {
  const { load, url } = useContext(UserContext);
  const [auth, setAuth] = useState({ data: [], links: [], user: [] });
  const [id, setId] = useState({});
  const [modal, setModal] = useState(false);
  const [stat, setStat] = useState("");
  const [radd, setRadd] = useState("");
  const [xol, setXol] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [active, setActive] = useState({ a1: "active", a2: "", a3: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [startDate, setStartDate] = useState(""); // تاریخ شروع
  const [endDate, setEndDate] = useState(""); // تاریخ پایان

  const tab = () => {
    if (active.a1) {
      return "pending";
    } else if (active.a2) {
      return "approved";
    } else if (active.a3) {
      return "rejected";
    }
  };

  const resetDate = () => {
    setStartDate("");
    setEndDate("");
    setSearchTerm("");

    console.log(
      "my reset date query ",
      url + `api/v1/panel/requests/withdraw?type=${tab()}`
    );

    axios
      .get(
        url + `api/v1/panel/requests/withdraw?type=${tab()}`,

        {
          headers: {
            Authorization: `bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        load(false);
        setAuth(res.data.withdraws || res.data.results);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  };

  const handleDownloadExcel = () => {
    const buildQuery = () => {
      let query = "";
      if (startDate) query += `&start_date=${startDate}`;
      if (endDate) query += `&end_date=${endDate}`;
      if (searchTerm.length > 2) query += `&query=${searchTerm}`;
      // سایر فیلترها (در صورت نیاز)
      return query;
    };

    const queryString = buildQuery();
    const downloadUrl = `${url}api/v1/panel/requests/withdraw/?type=${
      active.a1 ? "pending" : active.a2 ? "approved" : "rejected"
    }${queryString}&&export=excel`;
    console.log('my query for excel : ',downloadUrl)

    load(true);
    axios
      .get(downloadUrl, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
        responseType: "blob", // برای دریافت فایل
      })
      .then((response) => {
        load(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "withdraw_requests.xlsx"); // نام فایل
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        load(false);
        NotificationManager.error("خطا در دریافت فایل");
        console.log(err);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);

    // Clear the timeout if searchTerm changes (user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  // const sendSearchRequest = (term) => {
  //   if (term.length > 2) {
  //     load(true);
  //     axios
  //       .get(url + "api/v1/panel/requests/withdraw/search?query=" + term, {
  //         headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //       })
  //       .then((res) => {
  //         load(false);
  //         setAuth(res.data.results);
  //       })
  //       .catch((err) => {
  //         load(false);
  //         console.log(err);
  //       });
  //   } else {
  //     load(true);
  //     axios
  //       .get(url + "api/v1/panel/requests/withdraw/search?query=" + term, {
  //         headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //       })
  //       .then((res) => {
  //         load(false);

  //         setAuth(res.data.results);
  //       })
  //       .catch((err) => {
  //         load(false);
  //         console.log(err);
  //       });
  //   }
  // };

  const sendSearchRequest = (term) => {
    let queryString = `?query=${term}`; // شروع ساخت query string

    // بررسی مقادیر startDate و endDate
    if (startDate && endDate) {
      queryString += `&start_date=${startDate}&end_date=${endDate}`;
    }

    // بررسی مقادیر active برای اضافه کردن type
    if (active.a2) {
      queryString += `&type=approved`;
    } else if (active.a3) {
      queryString += `&type=rejected`;
    }

    console.log(
      "Search query URL:",
      url + `api/v1/panel/requests/withdraw/search${queryString}`
    );

    if (term.length > 2) {
      load(true);
      axios
        .get(url + `api/v1/panel/requests/withdraw/search${queryString}`, {
          headers: { Authorization: `bearer ${Cookies.get("token")}` },
        })
        .then((res) => {
          load(false);
          setAuth(res.data.results);
        })
        .catch((err) => {
          load(false);
          console.log(err);
        });
    } else {
      load(true);
      axios
        .get(url + `api/v1/panel/requests/withdraw/search${queryString}`, {
          headers: { Authorization: `bearer ${Cookies.get("token")}` },
        })
        .then((res) => {
          load(false);
          setAuth(res.data.results);
        })
        .catch((err) => {
          load(false);
          console.log(err);
        });
    }
  };

  // const sendSearchRequest = (term) => {
  //   let queryString = `?query=${term}`; // شروع ساخت query string

  //   // بررسی مقادیر startDate و endDate
  //   if (startDate && endDate) {
  //     queryString += `&start_date=${startDate}&end_date=${endDate}`;
  //   }

  //   console.log("Search query URL:", url + `api/v1/panel/requests/withdraw/search${queryString}`);

  //   if (term.length > 2) {
  //     load(true);
  //     axios
  //       .get(url + `api/v1/panel/requests/withdraw/search${queryString}`, {
  //         headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //       })
  //       .then((res) => {
  //         load(false);
  //         setAuth(res.data.results);
  //       })
  //       .catch((err) => {
  //         load(false);
  //         console.log(err);
  //       });
  //   } else {
  //     load(true);
  //     axios
  //       .get(url + `api/v1/panel/requests/withdraw/search${queryString}`, {
  //         headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //       })
  //       .then((res) => {
  //         load(false);
  //         setAuth(res.data.results);
  //       })
  //       .catch((err) => {
  //         load(false);
  //         console.log(err);
  //       });
  //   }
  // };

  useEffect(() => {
    load(true);
    axios
      .get(url + "api/v1/panel/requests/withdraw", {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        load(false);
        setAuth(res.data.withdraws);
        console.log(res.data.withdraws);
        load(true);
        axios
          .get(url + "api/v1/admin/transactions/get_wallet", {
            headers: {
              Authorization: `bearer ${Cookies.get("token")}`,
            },
          })
          .then((res) => {
            load(false);
            setWallet(res.data.balance);
          })
          .catch((err) => {
            load(false);
            console.log(err);
          });
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);
  // const liShows = () =>{
  //     return <>
  //     {
  //         auth.links.map(dd=>{
  //             let c = ()=>{
  //                 if(dd.active){
  //                     return "ac"
  //                 }else{
  //                     return null
  //                 }
  //             }
  //             if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
  //                 return <li key={dd.label} className={"page__numbers"+" "+ c()} onClick={()=>{
  //                     if(dd.url !== null){
  //                         load(true)
  //                     axios.get(dd.url+onSearch(),
  //                         { headers: { "Authorization": `bearer ${Cookies.get('token')}`
  //                     } }).then(res=>{
  //                         load(false)
  //                         console.log(res.data);

  //                        if(onSearch()=== ""){
  //                         setAuth(res.data.withdraws)
  //                        }else{
  //                         setAuth(res.data.results.withdraws)
  //                        }

  //                     }).catch(err=>{
  //                         load(false)
  //                         console.log(err);
  //                     })
  //                     }
  //                 }}> {dd.label} </li>
  //             }

  //         })
  //     }
  //     </>

  //  }
  const liShows = () => {
    return (
      <>
        {auth.links.map((dd) => {
          let c = () => (dd.active ? "ac" : null);

          if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
            return (
              <li
                key={dd.label}
                className={`page__numbers ${c()}`}
                onClick={() => {
                  if (dd.url !== null) {
                    load(true);
                    axios
                      .get(dd.url + onSearch(), {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);
                        setAuth(res.data.withdraws || res.data.results);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
              >
                {dd.label}
              </li>
            );
          }
        })}
      </>
    );
  };

  const authShow = () => {
    const status = (s, d) => {
      if (s === 0) {
        return (
          <div
            onClick={() => {
              console.log(d);
              setStat(0);
              setId(d);
              setModal(true);
            }}
            className="orange"
            style={{ cursor: "pointer" }}
          >
            {" "}
            در انتظار{" "}
          </div>
        );
      } else if (s === 1) {
        return (
          <div
            onClick={() => {
              console.log(d);
              setStat(1);
              setId(d);
              setModal(true);
            }}
            className="green"
            style={{ cursor: "pointer" }}
          >
            {" "}
            تایید{" "}
          </div>
        );
      } else if (s === 2) {
        return (
          <div
            onClick={() => {
              console.log(d);
              setStat(2);
              setId(d);
              setModal(true);
            }}
            className="red"
            style={{ cursor: "pointer" }}
          >
            {" "}
            رد شده{" "}
          </div>
        );
      }
    };
    return (
      <>
        {auth.data.map((d) => {
          return (
            <>
              <tr className="font">
                <th> {d.user.username} </th>
                <th> {d.amount} </th>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    padding: "3px",
                  }}
                  className="transId "
                >
                  {d.address}
                </th>
                <th> {d.created_at} </th>
                <th> {status(d.status, d)} </th>
              </tr>
            </>
          );
        })}
        <div>{/* سایر عناصر فیلتر */}</div>
        <button
          className="btn"
          onClick={handleDownloadExcel}
          style={{ margin: "10px" }}
        >
          دریافت فایل اکسل
        </button>
      </>
    );
  };
  const showXol = () => {
    if (xol === 1) {
      return (
        <>
          <textarea
            className="raddWhy"
            value={radd}
            onChange={(e) => {
              setRadd(e.target.value);
            }}
            placeholder="در صورت رد علت رد را وارد نمایید"
          />
          <div className="closeModal">
            <button
              className="btnreply mt"
              onClick={() => {
                load(true);
                axios
                  .post(
                    url + "api/v1/admin/withdraw/verify/" + id.id,
                    {
                      status: 2,
                      text: radd,
                      back: 1,
                    },
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then((res) => {
                    load(false);
                    setModal(false);
                    NotificationManager.success(
                      "درخواست پرداخت با موفقیت رد شد",
                      "موفق"
                    );
                  })
                  .catch((err) => {
                    load(false);
                    NotificationManager.error("درخواست پرداخت رد نشد");
                    console.log(err);
                  });
              }}
            >
              {" "}
              رد با بازگشت وجه{" "}
            </button>
            <button
              className="btnreply mt"
              onClick={() => {
                load(true);
                axios
                  .post(
                    url + "api/v1/admin/withdraw/verify/" + id.id,
                    {
                      status: 2,
                      text: radd,
                    },
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then((res) => {
                    load(false);
                    setModal(false);
                    NotificationManager.success(
                      "درخواست پرداخت با موفقیت رد شد",
                      "موفق"
                    );
                  })
                  .catch((err) => {
                    load(false);
                    NotificationManager.error("درخواست پرداخت رد نشد");
                    console.log(err);
                  });
              }}
            >
              {" "}
              رد بدون بازگشت وجه{" "}
            </button>
          </div>
        </>
      );
    } else if (xol === 2) {
      return (
        <>
          <textarea
            className="raddWhy"
            value={radd}
            onChange={(e) => {
              setRadd(e.target.value);
            }}
            placeholder="توضیحات را وارد نمایید"
          />
          <div className="closeModal">
            <button
              style={{ width: "100%" }}
              className="btnreply mt"
              onClick={() => {
                load(true);
                axios
                  .post(
                    url + "api/v1/admin/withdraw/verify/" + id.id,
                    {
                      status: 1,
                      text: radd,
                    },
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then((res) => {
                    axios
                      .get(url + "api/v1/admin/transactions/get_wallet", {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);
                        setWallet(res.data.balance);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                    NotificationManager.success(
                      "درخواست پرداخت با موفقیت تایید شد",
                      "موفق"
                    );
                    setModal(false);
                  })
                  .catch((err) => {
                    load(false);
                    NotificationManager.error("درخواست پرداخت تایید نشد");
                    console.log(err);
                  });
              }}
            >
              {" "}
              تایید نهایی پرداخت{" "}
            </button>
          </div>
        </>
      );
    }
  };
  // const onSearch = ()=>{
  //     if(searchTerm.length >2){
  //        return "&&query="+searchTerm
  //     }else{
  //       return ""
  //     }
  // }
  const onSearch = () => {
    let query = "";

    if (searchTerm.length > 2) {
      query += `&query=${searchTerm}`;
    }
    if (startDate) {
      query += `&start_date=${startDate}`;
    }
    if (endDate) {
      query += `&end_date=${endDate}`;
    }

    return query;
  };

  const verifyBy = (v, g, vr) => {
    if (g === 1) {
      if (v === 1 || v === null) {
        return "کیف پول";
      } else {
        return vr.first_name + " " + vr.last_name;
      }
    } else {
      if (v === 0) {
        return "Coinsbuy";
      } else if (v === null) {
        return "کیف پول";
      }
    }
  };

  const inModal = () => {
    if (stat === 0) {
      return (
        <>
          <div className="bb" style={{ direction: "rtl" }}>
            {" "}
            تایید یا رد پرداخت {" " + id.amount + " $"}{" "}
          </div>
          <div className="orderDetail bb">
            <div className="fl" style={{ padding: "5px" }}>
              {" "}
              نام کاربری{" "}
            </div>
            <div style={{ padding: "5px" }}> {id.user.username} </div>
          </div>
          <div style={{ textAlign: "right", padding: "10px" }} className=" bb">
            <table className="table table-hover font " border={1}>
              <thead className="f12">
                <tr>
                  <th style={{ textAlign: "center" }}> # </th>
                  <th> مبلغ </th>
                  <th> TX-ID </th>
                  <th> درگاه </th>
                  <th> توسط </th>
                  <th> نوع </th>
                </tr>
              </thead>
              <tbody className="f12">
                {id.user.transactions.map((d) => {
                  return (
                    <tr key={d.id}>
                      <td style={{ textAlign: "center" }}> {d.id} </td>
                      <td className="f12"> {d.amount} </td>
                      {d.traceNumber !== null ? (
                        <td
                          className="f12"
                          onClick={() => {
                            window.open(
                              "https://tronscan.org/#/transaction/" +
                                d.traceNumber,
                              "_blank"
                            );
                          }}
                        >
                          {" "}
                          {d.traceNumber}{" "}
                        </td>
                      ) : (
                        <td style={{ textAlign: "center" }} className="f12">
                          {" "}
                          خرید از کیف پول / MondFx{" "}
                        </td>
                      )}
                      <td className="f12">
                        {" "}
                        {d.gateway === 2 ? "آنلاین" : "کیف پول"}{" "}
                      </td>
                      <td className="f12">
                        {" "}
                        {verifyBy(d.verified_by, d.gateway, d.verifier)}{" "}
                      </td>
                      <td className="f12">
                        {" "}
                        {d.type === "withdraw" ? "برداشت" : "واریز"}{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {xol !== 3 && xol !== 4 ? (
            <>
              <p className="mt">
                {" "}
                با توجه به بررسی های انجام شده آیا این درخواست پرداخت دارای
                اعتبار می باشد ؟
              </p>
              <div style={{ marginBottom: "20px" }} className="closeModal">
                <button
                  className="btnreply "
                  onClick={() => {
                    setXol(1);
                  }}
                >
                  {" "}
                  رد پرداخت{" "}
                </button>
                <button
                  className="btnreply"
                  onClick={() => {
                    setXol(2);
                  }}
                >
                  {" "}
                  تایید پرداخت{" "}
                </button>
              </div>
            </>
          ) : null}
          {showXol()}
        </>
      );
    } else if (stat === 2) {
      return (
        <div>
          <div className="bb" style={{ textAlign: "center" }}>
            {"اطلاعات پرداخت کاربر " + id.user.username}
          </div>
          <div className="varizCon">
            <div className="add"> علت رد : </div>
            <div className="wallet"> {id.description} </div>
          </div>
        </div>
      );
    } else if (stat === 1) {
      return (
        <div>
          <div className="bb" style={{ textAlign: "center" }}>
            {"اطلاعات پرداخت کاربر " + id.user.username}
          </div>
          <div className="varizCon">
            <div className="add"> TX-ID : </div>
            <div className="wallet"> {id.transaction.traceNumber} </div>
          </div>
          <div className="varizCon">
            <div className="add"> توضیحات : </div>
            <div className="des"> {id.description} </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <NotificationContainer />
      <Success
        show={modal}
        closeModal={() => {
          setModal(false);
        }}
        name="inside23da"
      >
        {inModal()}
      </Success>

      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              setActive({ a1: "active", a2: "", a3: "" });
              load(true);
              axios
                .get(url + "api/v1/panel/requests/withdraw?type=pending", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data.withdraws);
                  console.log(res.data.withdraws);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={active.a1}
          >
            درخواست های پرداخت
          </a>
          <a
            onClick={() => {
              setActive({ a1: "", a2: "active", a3: "" });
              load(true);
              axios
                .get(url + "api/v1/panel/requests/withdraw?type=approved", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data.withdraws);
                  console.log(res.data.withdraws);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={active.a2}
          >
            تایید شده
          </a>
          <a
            onClick={() => {
              setActive({ a1: "", a2: "", a3: "active" });
              load(true);
              axios
                .get(url + "api/v1/panel/requests/withdraw?type=rejected", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data.withdraws);
                  console.log(res.data.withdraws);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={active.a3}
          >
            رد شده
          </a>
        </div>
        <div style={{ overflowX: "scroll" }} className="transAction">
          <div className="eachTicket">
            <div className="bb">
              <p> با کلیک روی وضعیت عملیات مورد نظر را اعمال نمایید : </p>
              <p>
                {" "}
                موجودی نهایی کیف پول :{" "}
                <span
                  className="green"
                  style={{ padding: "5px", fontSize: "11px" }}
                >
                  {" "}
                  {" $ " + wallet}{" "}
                </span>{" "}
              </p>
            </div>

            <input
              style={{ width: "35%", margin: "10px" }}
              className="searchIn"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              type="text"
              placeholder="جستجو نام کاربری ..."
              value={searchTerm}
            />
            {(active.a2 || active.a3) && (
              <div style={{ display: "flex", gap: "1rem", margin: "10px" }}>
                <label>
                  از تاریخ:
                  <input
                    className="searchIn"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    style={{ marginRight: "10px", width: "8rem" }}
                  />
                </label>
                <label>
                  تا تاریخ:
                  <input
                    className="searchIn"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    style={{ marginRight: "10px", width: "8rem" }}
                  />
                </label>
                <button
                  className="btn"
                  style={{
                    padding: "0px 1rem",
                    height: "2rem",
                  }}
                  onClick={() => {
                    setSearchTerm("");
                    load(true);
                    console.log(
                      "my query:",
                      url +
                        `api/v1/panel/requests/withdraw?type=${tab()}` +
                        onSearch()
                    );
                    axios
                      .get(
                        url +
                          `api/v1/panel/requests/withdraw?type=${tab()}` +
                          onSearch(),
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        setAuth(res.data.withdraws || res.data.results);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }}
                >
                  اعمال فیلتر تاریخ
                </button>
                <button
                  className="btn"
                  onClick={resetDate}
                  // style={{ margin: "10px" }}
                  style={{
                    padding: "0px 1rem",
                    height: "2rem",
                  }}
                >
                  لغو فیلتر تاریخ
                </button>
              </div>
            )}

            {/* <select value={search} style={{width:"35%" , margin:"10px"}}
                                        className='searchIn'
                                     onChange={(e)=>{
                                    setSearch(e.target.value)
                  }} type='text' >
                   <option value="username" >   نام کاربری  </option>
                   <option value="first_name">   نام   </option>
                   <option value="last_name">   نام خانوادگی   </option>
                   <option value="traceNumber">   TX-id   </option>
                  </select> */}
            <div className="table-responsive-md">
              <table border={1} className="table table-hover ">
                <thead>
                  <tr className="font">
                    <th> کاربر </th>
                    <th> مبلغ </th>
                    <th> آدرس کیف پول </th>
                    <th> تاریخ ایجاد </th>
                    <th> وضعیت </th>
                  </tr>
                </thead>
                <tbody>{authShow()}</tbody>
              </table>
              <ul class="page">
                <li
                  onClick={() => {
                    if (auth.prev_page_url !== null) {
                      load(true);
                      axios
                        .get(auth.prev_page_url + onSearch(), {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        })
                        .then((res) => {
                          load(false);
                          if (onSearch() === "") {
                            setAuth(res.data.data);
                          } else {
                            setAuth(res.data.results);
                          }
                        })
                        .catch((err) => {
                          load(false);
                          console.log(err);
                        });
                    }
                  }}
                  className="page__btn"
                >
                  <span class="material-icons">
                    <IoChevronBackCircle />
                  </span>
                </li>
                {liShows()}
                <li
                  onClick={() => {
                    if (auth.next_page_url !== null) {
                      load(true);
                      axios
                        .get(auth.next_page_url + onSearch(), {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        })
                        .then((res) => {
                          load(false);

                          if (onSearch() === "") {
                            setAuth(res.data.data);
                          } else {
                            setAuth(res.data.results);
                          }
                        })
                        .catch((err) => {
                          load(false);
                          console.log(err);
                        });
                    }
                  }}
                  className="page__btn"
                >
                  <span class="material-icons">
                    <IoArrowForwardCircleSharp />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
