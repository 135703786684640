// src/components/OneHourComponent.js
import React, { useEffect, useState,useContext } from 'react';
import Cookies from 'js-cookie';
import UserContext from './UserContext/UserContext';
import validator from 'validator';
import { TbPasswordFingerprint } from "react-icons/tb";
import { BiSolidLogInCircle } from "react-icons/bi";
import axios from 'axios';
import Success from './Success/Success';
import { NotificationContainer, NotificationManager } from 'react-notifications';
const AutoLogin = () => {
  const [showComponent, setShowComponent] = useState(false);
  const {  load , url  , logout } = useContext(UserContext);
	const [number, setNumber] = useState("")
	const numChange = (event) => {
        setNumber(event.target.value);
    };
	const [password, setPassword] = useState("")
	const passChange = (event) => {
        setPassword(event.target.value);
    };
  useEffect(() => {
    const checkTimeElapsed = () => {
      const loginTimestamp = Cookies.get('time');
      if (loginTimestamp) {
        const parsedTimestamp = parseInt(loginTimestamp, 10);
        if (!isNaN(parsedTimestamp)) {
          const now = new Date().getTime();
          const timeElapsed = now - parsedTimestamp;
          if (timeElapsed >= 3200000) { // 3600000 milliseconds = 1 hour
            if(timeElapsed >= 3610000){
			        	Cookies.remove("time")
                window.location.reload()
            }else{
              setShowComponent(true);
              
            }
            
          }
        }
      }
    };

    checkTimeElapsed(); // Check immediately on mount

    // Set an interval to check periodically
    const interval = setInterval(checkTimeElapsed, 1000); // Check every second

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const loginCheck = ()=>{
		 
     
      if((validator.isEmail(number) ||
       validator.isMobilePhone(number , ['fa-IR']) || 
       number==="Mohammad") && password !==""){
        
			  load(true)
        const tokenSet = () =>{
          if(Cookies.get("support_token")){
            return Cookies.get("support_token")
          }else{
            return Cookies.get("token")
          }
        }
				axios.post(url+"api/v1/auth/revival",{username: number , password: password },
          { headers: { "Authorization": `bearer ${tokenSet()}` } }).then(
					(res)=>{
            console.log(res.data);
            
						let acctok = res.data.access_token
						const now = new Date().getTime()
						load(false)
						Cookies.set("token", acctok, { expires: 7, secure: false });
						Cookies.set('time', now.toString(), { expires: 7, secure: false });
            setShowComponent(false)
            if(Cookies.get("support_token")){
              Cookies.remove("support_token")
              window.location.reload()
            }
					}
				).catch((err)=>{
					console.log(err);
					if(err.response !== undefined){
						if(err.response.status === 401){
						NotificationManager.error("اطلاعات کاربری صحیح نمی باشد","ورود انجام نشد", 5000)
						load(false)
						}else if(err.response.status === 429){
						NotificationManager.error("تعداد تلاش نا موفق بیش از حد مجاز چند دقیقه بعد دوباره تلاش نمایید","ورود انجام نشد",5000)
						load(false)
						}else if(err.response.status === 422){
						NotificationManager.error("لطفا نام کاربری و رمز عبور را وارد کنید","ورود انجام نشد",5000)
						load(false)
						}else if(err.response.status === 403){
							NotificationManager.error("به علت رفتار مشکوک اکانت شما مسدود شده ، با پشتیبانی تماس بگیرید","ورود انجام نشد",5000)
							load(false)
							}else if(err.response.status === 400){
                NotificationManager.error("عدم تطابق نام کاربری با نام کاربری فعلی","ورود انجام نشد",5000)
                load(false)
                }else{
						NotificationManager.error(" مشکل در سرور ، بزودی برمیگردیم ","ورود انجام نشد",5000)
						load(false)
						}
					}else{
						NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						load(false)
					}	
				})
			 
		}else{
          if(password === ""){
			NotificationManager.error("گذرواژه خالی می باشد","ورود انجام نشد")
		  }else{
			NotificationManager.error("ایمیل یا شماره همراه معتبر نمی باشد","ورود انجام نشد")
		  }
		}
    
       		
	}
  return (
    <div style={{zIndex:"999"}} >
      <NotificationContainer/>
      <Success show={showComponent} closeModal={()=>{setShowComponent(true)}} name="allOrdehgrs3sdfs242" >
        <div style={{color:"white"}} className='bb'> 
        تمدید ورود
        </div>
      <form style={{direction:"ltr"}} onSubmit={(e)=> {e.preventDefault()}} className="login">
				
        <div className="login__field1 mt">
          <input onChange={numChange} type="text" className="login__input1" placeholder="شماره همراه / ایمیل"/>
          <BiSolidLogInCircle color='white' />
        </div>
       <div className="login__field1">
      
      <input onChange={passChange} type="password" className="login__input1" placeholder="رمز عبور"/>
      <TbPasswordFingerprint color='white' />
       </div>
       <button onClick={loginCheck} className="button login__submit1">
      <span className="button__text"> تمدید ورود </span>
      <i className="button__icon fas fa-chevron-right"></i>
       </button>	
       <button onClick={()=>{
        Cookies.remove("support_token")
        Cookies.remove("token")
        Cookies.remove("time")
        setShowComponent(false)
        logout()
        window.location.reload()
       }} className="button login__submit1">
      <span className="button__text"> خروج </span>
      <i className="button__icon fas fa-chevron-right"></i>
       </button>			
      </form>
        </Success>
      
    </div>
  );
};

export default AutoLogin;
