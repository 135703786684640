/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
import React, { useState , useContext, useEffect } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success';
import moment from 'moment-jalaali';
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
import ReactPlayer from 'react-player';
export default function SupportAuth() {
const { load , url , user} = useContext(UserContext);
const [auth , setAuth] = useState({data:[] , links:[], user:[]})
const [id , setId] = useState("")
const [id2 , setId2] = useState("")
const [radd , setRadd] = useState("")
const [showUser , setShowUser] = useState(false)
const [show , setShow] = useState(false)
const [modal , setModal] = useState(false)
const [searchTerm, setSearchTerm] = useState('');
const [each , setEach] = useState([])
const [act , setAct] = useState({a1:"",a2:"",a3:""})
const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
useEffect(() => {
  if (debouncedSearchTerm) {
    sendSearchRequest(debouncedSearchTerm);
  }
}, [debouncedSearchTerm]);
useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedSearchTerm(searchTerm);
  }, 2000);
  return () => {
    clearTimeout(handler);
  };
}, [searchTerm]);

const sendSearchRequest = (term) => {
      if(term.length >2){
      load(true)
      if(act.a3){
        axios.get(url+"api/v1/admin/authentications/confirmed/search?query="+
          term  ,
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            console.log(res.data);
            setAuth(res.data.data);
        }).catch(err=>{
            load(false)
            console.log(err);
        })
      }else{
        axios.get(url+"api/v1/panel/auth/search?query="+
          term  ,
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            console.log(res.data);
           setAuth(res.data.data);
          
          
          
        }).catch(err=>{
            load(false)
            console.log(err);
        })
      }
  }else{
    load(true)
    axios.get(url+"api/v1/panel/show_authes",
        {
            headers: {
                "Authorization": `bearer ${Cookies.get('token')}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            load(false)
            setAuth(res.data.data)
            console.log(res.data.data);
        }).catch(err => {
            load(false)
            console.log(err);
        })
  }
  
};
    useEffect(()=>{
        load(true)
        axios.get(url+"api/v1/panel/show_authes",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
                console.log(res.data.data);
            }).catch(err => {
                load(false)
                console.log(err);
            })
    },[])
    const authShow = ()=>{
      
        return <>
          {auth.data.map(d=>{
            return <>
                    <tr>
                       <th> {d.first_name !==null?d.first_name+ " " + d.last_name:d.username} </th>
                       <th> {d.username} </th>
                       <th> {d.created_at} </th>
                       <th className='lastTh'>
                       <MdAdsClick onClick={()=>{
                        if(showUser){
                            setShowUser(false)
                        }else{
                            setId(d.id)
                            setShowUser(true)
                            if(act.a3){
                              setEach(d.confirmed_authentications)
                            }else{
                              setEach(d.auth_requests)
                            }   
                        }
                       }}/>
                       </th>
                    </tr>
                    {id === d.id && showUser ? <tr >
                                   
                    <td className='tickCon' colSpan={4}>
                      {each.map(dd=>{
                            
                            if(dd.type === "SelfImage_auth"){
                            return <div className='flex allCon'>
                                  <div className='side' >
                                     <div> تعیین صلاحیت احراز هویت عکس سلفی با کارت ملی کاربر </div>
                                     {act.a3?
                                      <div style={{padding:"10px",
                                        borderRadius:"5px",
                                        border:"1px solid",
                                        display:"flex",
                                        justifyContent:"space-between"
                                        }}>
                                        <div> تایید کننده : </div>
                                        <div> {dd.user!==null&&dd.user.first_name+" "+dd.user.last_name} </div>
                                      </div>
                                      : <div className='flex btnCon' >   
                                      <button onClick={()=>{
                                        setId2(dd)
                                        setModal(true)
                                      }} className='btnreply mt'>تایید</button>
                                      <button onClick={()=>{
                                        setShow(true)
                                      setId2(dd)
                                   }} className='btnreply mt'>رد</button>
                                    </div>}
                                     </div>
                                    <div className='side'> 
                                      <img onClick={()=>{
                                        window.open(url+"storage"+d.self_image, "_blank") 
                                    }} alt='' className='image' src={url+"storage"+d.self_image} /> 
                                  </div>
                                </div>
                               }else if(dd.type === "Video_auth"){
                                console.log(dd);
                                
                                return <>
                                
                                <div className='flex allCon'>
                                        <div className='side' >
                                          {act.a3!=="active"?<div className='mt' >
                                            <img onClick={()=>{
                                     window.open(url+"storage/"+d.id_card_image, "_blank") 
                                    }} style={{width:"200px" , height:"200px"}} src={url+"storage/"+d.id_card_image} alt='' />
                                            <img onClick={()=>{
                                     window.open(url+"storage/"+d.self_image, "_blank") 
                                    }} style={{width:"200px", height:"200px"}} src={url+"storage/"+d.self_image} alt=''/>
                                          </div>:null}
                                         <div style={{textAlign:"center" , padding:"10px"}}>
                                           تعیین صلاحیت احراز هویت ویدیویی 
                                         </div>
                                          {act.a3?
                                            <div style={{padding:"10px",
                                            borderRadius:"5px",
                                            border:"1px solid",
                                            display:"flex",
                                            justifyContent:"space-between"
                                            }}>
                                           <div> تایید کننده : </div>
                                            <div> {dd.user!==null&&dd.user.first_name+" "+dd.user.last_name} </div>
                                           </div>
                                           :  <div className='flex btnCon' >   
                                           <button onClick={()=>{
                                            setId2(dd)
                                            setModal(true)
                                           }} className='btnreply '>تایید</button>
                                           <button onClick={()=>{
                                            setShow(true)
                                            setId2(dd)
                                           }} className='btnreply '>رد</button>
                                          </div>}
                                         </div>
                                        <div className='side'> 
                                        <ReactPlayer url={url+"storage/"+d.video_path} controls className="video" 
                                         width="90%"  
                                         style={{margin:"10px auto" , maxHeight:"300px" }}/> 
                                      </div>
                                    </div></>
                                   }else if(dd.type === "IdCard_auth"){
                                  return <div className='flex allCon'>
                                  
                                  <div className='side' >
                                   <div> تعیین صلاحیت احراز هویت عکس کارت ملی کاربر </div>
                                   {act.a3?
                                  <div style={{padding:"10px",
                                  borderRadius:"5px",
                                  border:"1px solid",
                                  display:"flex",
                                  justifyContent:"space-between"
                                  }}>
                                    <div> تایید کننده : </div>
                                    <div> {dd.user!==null&&dd.user.first_name+" "+dd.user.last_name} </div>
                                  </div>
                                  : 
                                   <div className='flex btnCon'>   
                                      <button onClick={()=>{
                                        setId2(dd)
                                        setModal(true)
                                      }} className='btnreply mt'>تایید</button>
                                      <button onClick={()=>{
                                        setId2(dd)
                                        setShow(true)
                                      }} className='btnreply mt'>رد</button>
                                    </div>}
                                   </div>
                                  <div className='side'> 
                                    <img onClick={()=>{
                                     window.open(url+"storage"+d.id_card_image, "_blank") 
                                    }} alt='' className='image' src={url+"storage"+d.id_card_image} /> 
                                  </div>
                                </div>
                               }else if(dd.type === "Identity_auth"){
                                return <div className='flex allCon'>
                                <div className='side' >
                                <div> تعیین صلاحیت  احراز اطلاعات هویتی کاربر </div>
                                 {act.a3?
                                  <div style={{padding:"10px",
                                  borderRadius:"5px",
                                  border:"1px solid",
                                  display:"flex",
                                  justifyContent:"space-between"
                                  }}>
                                    <div> تایید کننده : </div>
                                    <div> {dd.user!==null&&dd.user.first_name+" "+dd.user.last_name} </div>
                                  </div>
                                  : <div className='flex btnCon'>   
                                    <button onClick={()=>{
                                        setId2(dd)
                                        setModal(true)
                                      }} className='btnreply mt'>تایید</button>
                                    <button onClick={()=>{
                                      setShow(true)
                                      setId2(dd)
                                    }} className='btnreply mt'>رد</button>
                                  </div>}
                                 </div>
                                <div className='side'> 
                                  <div className='idenCon'>
                                        <div className='dfjc '>
                                          <div> نام و نام خانوادگی </div>
                                          <div>{d.first_name+ " " + d.last_name}</div>
                                        </div>
                                        <div className='dfjc mt'>
                                          <div> تاریخ تولد </div>
                                          <div>{ moment(d.birth_date).format('jYYYY/jM/jD')}</div>
                                        </div>
                                        <div className='dfjc mt'>
                                          <div> جنسیت </div>
                                          <div>{d.gender}</div>
                                        </div>
                                        <div className='dfjc mt'>
                                          <div> کد ملی </div>
                                          <div>{d.id_number}</div>
                                        </div>
                                  </div>
                                </div>
                              </div>
                               }
                      })}
                      
                    </td>
                    </tr>: null}
                   </>
                   

          })}
        </>
    }
    const liShows = () =>{
      return <>
      {
          auth.links.map(dd=>{
              let c = ()=>{
                  if(dd.active){
                      return "ac"
                  }else{
                      return null
                  }
              }
              if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                  return <li key={dd.label} className={"page__numbers"+" "+ c()} onClick={()=>{
                      if(dd.url !== null){
                          load(true)
                      axios.get(dd.url+onSearch(),
                          { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                      } }).then(res=>{
                          load(false)
                          setAuth(res.data.data)
                      }).catch(err=>{
                          load(false)
                          console.log(err);
                      })
                      }
                  }}> {dd.label} </li>
              }
           
          })
      }
      </>
      
   }
   const onSearch = ()=>{
    if(searchTerm.length >2){
       return "&&query="+searchTerm
    }else{
      return ""
    }
   }
  return (
    <>
    <NotificationContainer/>
    <Success show={show} closeModal={()=>{setShow(false)}} name="SupportAuth" >
     <div>
        <div className='bb'> پشتیبان عزیز علت رد را بنویسید  </div>
        <textarea value={radd} onChange={(e)=>{
          setRadd(e.target.value)
        }} className='textarea mt' />
        <button onClick={()=>{
         if(radd=== ""){
       NotificationManager.error("علت رد خالی می باشد لطفا علت رد را وارد نمایید" , "علت رد ثبت نشد" , 4000)
         }else{
            load(true)
            let data = {auth_id: id2.id , description: radd , auth_status: 2}
            console.log(data);
        axios.post(url+"api/v1/panel/auth_confirmation", data ,
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
              if(res.data.message === "First Confirm Identity Auth"){
                NotificationManager.error("ابتدا اطلاعات هویتی کاربر را تایید نمایید" , " رد نشد" , 7000)
                load(false)
                setShow(false)
              }else{
                load(false)
                NotificationManager.success("احراز هویت کاربر با موفقیت رد شد","تایید",5000)
                   let array = each; 
                   let index = array.indexOf(id2)
                   if (index !== -1) {
                   array.splice(index, 1);
                   setEach(array);
                   }
                 setRadd("")
                 setShowUser(false)
                 setShow(false)  
              }
            }).catch(err => {
                load(false)
                console.log(err);
                if(err.response.status === 400){
                  NotificationManager.error("امکان رد اطلاعات هویتی کاربر بدون وجود تصویر کارت ملی وجود ندارد",
                    "انجام نشد",4000)
                 }
            })
         }
        }} className='btnreply mt'> ثبت علت </button>
     </div>
    </Success>
    <Success show={modal} closeModal={()=>{setModal(false)} } name="inside23" >
     <div >
        <div className='bb'> آیا از تایید احراز هویت اطمینان دارید؟ </div>
        <p className='mt'> در صورت تایید یا رد شدن احراز هویت امکان بازیابی مجدد آن وجود ندارد در صورتی
             که قصد تایید این مورد را ندارید دکمه خیر را بزنید</p>
             
             <div className='closeModal'>
                <button className='btnreply mt' onClick={()=>{
                    setModal(false)
                }}> خیر </button>
                <button className='btnreply mt' onClick={()=>{
                 load(true)
                 let data = {auth_id: id2.id , description: "احراز هویت تایید گردید" , auth_status: 1}
                 
                 axios.post(url+"api/v1/panel/auth_confirmation", data ,
                 {
                     headers: {
                         "Authorization": `bearer ${Cookies.get('token')}`,
                         'Content-Type': 'multipart/form-data'
                     }
                 }).then(res => {
                  if(res.data.message === "First Confirm Identity Auth"){
                    NotificationManager.error("ابتدا اطلاعات هویتی کاربر را تایید نمایید" , " تایید نشد" , 7000)
                    setModal(false)
                  }else{
                    let array = each; 
                        let index = array.indexOf(id2)
                        if (index !== -1) {
                           array.splice(index, 1);
                           setEach(array);
                          }
                     load(false)
                     NotificationManager.success("احراز هویت کاربر با موفقیت تایید شد","تایید",5000)
                      setShowUser(false)
                      setModal(false) 
                  }

                 }).catch(err => {
                     load(false)
                     console.log(err);
                     if(err.response.status === 400){
                      NotificationManager.error("امکان تایید اطلاعات هویتی کاربر بدون وجود تصویر کارت ملی وجود ندارد",
                        "انجام نشد",4000)
                     }
                 })
                }}> بله </button>
             </div>
     </div>
    </Success>
     <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
              setAct({a1:"active",a2:"",a3:""})
              setShowUser(false)
              load(true)
              axios.get(url+"api/v1/panel/show_authes",
                  {
                      headers: {
                          "Authorization": `bearer ${Cookies.get('token')}`,
                          'Content-Type': 'multipart/form-data'
                      }
                  }).then(res => {
                      load(false)
                      setAuth(res.data.data)
                      console.log(res.data.data);
                  }).catch(err => {
                      load(false)
                      console.log(err);
                  })
            }} className={act.a1}>
                احراز هویت های کاربران مرحله 1
            </a>
            {user.dataes.permissions.find(e=>e.name==="video_auth")?
            <>
            <a onClick={()=>{
              setAct({a1:"",a2:"active",a3:""})
              setShowUser(false)
              load(true)
              axios.get(url+"api/v1/panel/authentications/video",
                  {
                      headers: {
                          "Authorization": `bearer ${Cookies.get('token')}`,
                          'Content-Type': 'multipart/form-data'
                      }
                  }).then(res => {
                      load(false)
                      setAuth(res.data.data)
                      console.log(res.data.data);
                  }).catch(err => {
                      load(false)
                      console.log(err);
                  })
            }} className={act.a2}>
                احراز هویت ویدیویی مرحله 2
            </a>
            <a onClick={()=>{
              setAct({a1:"",a2:"",a3:"active"})
              setShowUser(false)
              load(true)
              axios.get(url+"api/v1/admin/authentications/confirmed?type=first",
                  {
                      headers: {
                          "Authorization": `bearer ${Cookies.get('token')}`,
                          'Content-Type': 'multipart/form-data'
                      }
                  }).then(res => {
                      load(false)
                      setAuth(res.data.data)
                      console.log(res.data.data);
                  }).catch(err => {
                      load(false)
                      console.log(err);
                  })
            }} className={act.a3}>
               تایید شده مرحله 1
            </a>
            <a onClick={()=>{
              setAct({a1:"",a2:"",a3:"",a4:"active"})
              setShowUser(false)
              load(true)
              axios.get(url+"api/v1/admin/authentications/confirmed?type=second",
                  {
                      headers: {
                          "Authorization": `bearer ${Cookies.get('token')}`,
                          'Content-Type': 'multipart/form-data'
                      }
                  }).then(res => {
                      load(false)
                      setAuth(res.data.data)
                      console.log(res.data.data);
                  }).catch(err => {
                      load(false)
                      console.log(err);
                  })
            }} className={act.a3}>
               تایید شده مرحله 2
            </a>
            </>
            :null}
        </div>
        <div className='transAction'>
                <div className='eachTicket'>
                    <p className='bb'>احراز هویت های کاربران : </p>
                    <input style={{width:"35%" , margin:"10px"}}
                                        className='searchIn'
                                     onChange={(e)=>{
                                        setSearchTerm(e.target.value)
                                     }} type='text' placeholder='جستجو نام کاربری ...'/>
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover  ">
                            <thead >
                                <tr>
                                    <th> نام و نام خانوادگی </th>
                                    <th> نام کاربری </th>
                                    <th> تاریخ عضویت </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {authShow()}
                            </tbody>
                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(auth.prev_page_url !== null){
                                  
                                    load(true)
                                axios.get(auth.prev_page_url+onSearch(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setAuth(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(auth.next_page_url !== null){          
                                    load(true)
                                axios.get(auth.next_page_url+onSearch(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setAuth(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}
