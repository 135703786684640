/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect , useRef , useContext } from 'react';
import moment from 'moment-jalaali';
import axios from 'axios';
import io from 'socket.io-client';
import "./SupportPanel.css"
import { TiTick } from "react-icons/ti";
import { GiCancel } from "react-icons/gi";
import Cookies from 'js-cookie';
import { BsFillSendFill , BsTicketPerforatedFill } from "react-icons/bs";
import { IoListCircle } from "react-icons/io5";
import UserContext from "../UserContext/UserContext"
import {FaCamera , FaRegFileAlt ,FaSolarPanel , FaEye , FaUser , FaReply } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { IoIosCloseCircle , IoIosSearch } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import Success from '../Success/Success';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CiCircleChevDown } from "react-icons/ci";
import { MdSupportAgent , MdAdsClick , MdCancel } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import userImage from "../../photoes/user.png";
import inChat from "../../sounds/inChat.mp3"
import { TbRefresh } from "react-icons/tb";
import otherChat from "../../sounds/otherChat.mp3"
import newChatSound from "../../sounds/newChat.mp3"
import { FaArrowTurnUp } from "react-icons/fa6";
import AdminChatPanel from '../Admin/AdminChatPanel';
const socket = io('https://chat.fenefx.net', {
  reconnection: true,          
  reconnectionAttempts: 5, 
  reconnectionDelay: 300,
  path:"/backend/socket.io"
 });
//SO START
function SupportPanel() {
    const [userData, setUserData] = useState({
        accounts:[],
        tickets:[], 
        confirmed_authentications:[],
        all_referrals: []}
      )
    const { load , url , user } = useContext(UserContext);
    const [dis, setDis] = useState({ a1: "", a2: "" , a3: "" , a4:"" })
    const [chats, setChats] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [chatsFiltered, setChatsFiltered] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [modal, setModal] = useState(false);
    const [file, setFile] = useState([]);
    const [classs1, setClass1] = useState([false])
    const [quickResponse, setQuickResponse] = useState([]);
    const [quickResponseMain, setQuickResponseMain] = useState([]);
    const [reply, setReply] = useState([]);
    const [typing, setTyping] = useState(null);
    const [personsChat, setPersonsChat] = useState([]);
    const messageEndRef = useRef(null);
    const refImage = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const handleSearch = () => {
        const matches = chats.filter(item => {
          if (item.user && item.user.username) {
            return item.user.username.toLowerCase().includes(inputValue.toLowerCase());
          } else {
            return String(item.user_id).toLowerCase().includes(inputValue.toLowerCase());
          }
        });
        setChatsFiltered(matches);
      };
      const truncateText = (text, wordLimit) => {
        const imagePattern = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
        const zipPattern = /\.zip$/i;
        const urlWithoutQuery = text.split('?')[0];
        if(text){
          if(imagePattern.test(urlWithoutQuery)){
            return "تصویر"
          }else if(zipPattern.test(urlWithoutQuery)){
          return "فایل"
          }else{
            if (text.length > wordLimit) {
            return text.slice(0, wordLimit) + '...';
           }else{
            return text;
           }
          }   
        }
      };
    useEffect(() => {
        load(true)
        let token = Cookies.get('token')
        if(user.dataes.roles[0].name !== "user"){
          socket.emit('joinSupportRoom',{token});
        }
        axios.get('https://chat.fenefx.net/backend/api/openChats',
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } })
            .then(response => {
                setChats(response.data)
                console.log(response.data);
                load(false)
            })
            .catch(err => {
                console.error( err);
                load(false)
                
            });

        // Listen for new chats
        socket.on('newChat', (newChat) => {
            console.log(newChat);
            setChats(prevChats => [newChat, ...prevChats]);
            const audio = new Audio(newChatSound); // Path to your sound file
              audio.play();
        });
          socket.on('openChatsList', (Chats) => {
            setChats(Chats);
        });
        
        return () => {
            socket.off('newChat');
            socket.off('openChatsList');
        };
    }, []);
    useEffect(() => {
      if (chats) {
        const handleNewMessage = (nm) => {
          if (chats.filter((d) => d.support_id === user.dataes.id).find((e) => e.id === nm.chat_id)) {
            socket.emit("getOpenChats")
            if (selectedChat && selectedChat.id === nm.chat_id) {
            
              const audio = new Audio(inChat); // Path to your sound file
              audio.play();
            } else {
              
              const audio = new Audio(otherChat); // Path to your sound file
              audio.play();
            }
          }
        };
    
        socket.on("newMessageChat", handleNewMessage);
    
        // Cleanup listener on unmount
        return () => {
          socket.off("newMessageChat", handleNewMessage);
        };
      }
    }, [chats]);
    useEffect(()=>{
        if (messageEndRef.current) {
            messageEndRef.current.scroll({
              top: messageEndRef.current.scrollHeight,
              behavior: "smooth",
            });
          }
     },[messages])
    
    useEffect(() => {
      
        if (selectedChat) { 
            socket.emit('joinChat', {chatId:selectedChat.id,token:Cookies.get("token")});
            socket.on('chatHistory', (chatMessages) => {
                if(chatMessages.find(e=>e.chat_id == selectedChat.id)) {
                    setMessages(chatMessages);
                }
            });
            socket.on("userTyping",(data)=>{
             if(data.chatId === selectedChat.id){  
                setTyping(data.message+" ... ")
             }
            })
            socket.on("userStoppedTyping",()=>{
              setTyping(null)
            })
            socket.on('newMessage', (newMessage) => {
              socket.emit('getOpenChats')
              if(newMessage.chat_id == selectedChat.id){
                socket.emit('readMessage', newMessage.chat_id  )
                socket.emit('markMessagesSeen',{chatId: newMessage.chat_id , userId:user.dataes.id})
                setMessages(prevMessages => [...prevMessages, newMessage]);
              }
            });
            return () => {
                socket.off('chatHistory');
                socket.off('newMessage');
                socket.off('userStoppedTyping');
                socket.off('userTyping');
            };
        }
    }, [selectedChat]);

    // const assignChat = (chatId, supportId) => {
    //     axios.post(`/api/assignChat/${chatId}`, { supportId })
    //         .then(() => {
    //             // Handle success
    //         })
    //         .catch(err => {
    //             console.error('Error assigning chat:', err);
    //             setError('Failed to assign chat');
    //         });
    // };
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          if (event.shiftKey) {
              // Shift + Enter pressed, insert a new line
              event.preventDefault();
              setMessage(message + '\n');
          } else {
              // Enter pressed, trigger the button click
              event.preventDefault();
              sendMessage();
          }
      }
  };
   const replyHandle = (msg)=>{
     setReply([msg])
   }
   const divRefs = useRef({});
   const scrollToDiv = (key) => {
    const targetDiv = divRefs.current[key];

    if (targetDiv) {
      // Use scrollIntoView with 'nearest' to avoid scrolling the entire page or parent
      targetDiv.scrollIntoView({
        behavior: "smooth",
        block: "nearest", // Only scroll if the div is not fully visible
        inline: "nearest",
      });
    }
  };
    const inModal = ()=>{
        if(file[1]==="image"){
            return <div style={{color:"white"}}>
            <div className='mt'> آیا از ارسال تصویر مورد نظر اطمینان دارید؟ </div>
             <div> برای ارسال تصویر گزینه ارسال را بزنید </div>
             <img style={{maxHeight:"300px", maxWidth:"300px" ,margin:"10px" }} 
             src={URL.createObjectURL(file[0])} alt='sendPhoto'/>
             <div> 
                <button className='btnreply' onClick={()=>{
                    setModal(false)
                    setFile([])
                  }}> انصراف </button>
                <button style={{marginRight:"5px"}} className='btnreply' onClick={()=>{
                    let data = {
                        file:file[0],
                        senderId:user.dataes.id,
                        chatId:selectedChat.id,
                        type:"support"
                    }
                    console.log(data)
                    load(true)
                    axios.post('https://chat.fenefx.net/backend/api/upload',data,{
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          "Authorization": `bearer ${Cookies.get('token')}` 
                        }
                    }) 
                        .then(response => {
                              load(false)
                              const senderType = 'support';
                              socket.emit('sendMessage', selectedChat.id,
                              response.data.fileUrl, user.dataes.id, senderType , "image");
                              socket.emit('sendChat', selectedChat.id, response.data.fileUrl);
                              socket.emit('getUnreadCounts' , selectedChat.user_id , "newMessage")
                              setModal(false);
                        })
                        .catch(err => {
                            load(false)
                            console.error(err);
            
                        });
                }} > ارسال </button>
                
             </div>
            </div>
        }else if(file[1]==="file"){
            return <div style={{color:"white"}}>
            <div className='mt'> آیا از ارسال فایل مورد نظر اطمینان دارید؟ </div>
            <div style={{direction:"rtl"}}> برای ارسال فایل {" ( "+file[2]+" ) "} گزینه ارسال را بزنید </div>
            <div  className='mt'> 
               
               <button  className='btnreply' onClick={()=>{
                   setModal(false)
                   setFile([])
               }}> انصراف </button>
               <button style={{marginRight:"5px"}} onClick={()=>{
                let data = {
                    file:file[0],
                    senderId:user.dataes.id,
                    chatId:selectedChat.id,
                    type:"support"
                }
                console.log(data)
                load(true)
                axios.post('https://chat.fenefx.net/backend/api/upload',data,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": `bearer ${Cookies.get('token')}` 
                    }
                }) 
                    .then(response => {
                          console.log(response.data);
                          const senderType = 'support';
                          socket.emit('sendMessage', selectedChat.id,
                             response.data.fileUrl, user.dataes.id, senderType , "file");
                          socket.emit('sendChat', selectedChat.id, response.data.fileUrl);
                          socket.emit('getUnreadCounts' , selectedChat.user_id, "newMessage")
                          setModal(false)
                          load(false)
                    })
                    .catch(err => {
                        load(false)
                        console.error(err);
        
                    });
               }} className='btnreply' > ارسال </button>
            </div>
           </div>
        }else if(file[1]==="pick"){
            return <> 
                      <div style={{color:"white"}} className='mt'>
                         آیا از انتخاب چت اطمینان دارید؟
                      </div>
                      <div className='dfjc mt'>
                        <button  onClick={()=>{
                            setModal(false)
                            setFile([])
                        }} className='btnreply'> انصراف </button>
                        <button onClick={()=>{
                           load(true)
                           axios.post(url+"api/v1/panel/chat/pick/"+selectedChat.id,{},
                          { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                           } }).then(res=>{
                               console.log(res.data);
                               load(true)
                               axios.get('https://chat.fenefx.net/backend/api/openChats',
                                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                }})
                                 .then(response => {
                                     setChats(response.data);
                                     console.log(response.data);
                                     load(false)
                                     setActiver({ a1: "active", a2: "", a3: "", a4: "" })
                                     setModal(false)
                                     NotificationManager.success("چت با موفقیت انتخاب شد")
                                     socket.emit("getOpenChats")
                                     setFile([])
                                 })
                                 .catch(err => {
                                     console.error('Error fetching chats:', err);
                                     load(false)
                                 });
                          }).catch(err=>{
                          load(false)
                          console.log(err);
                          NotificationManager.error("این چت توسط شخص دیگری انتخاب شده")
                           })
                        }} className='btnreply'> انتخاب </button>
                      </div>
                   </>
        }else if(file[1]==="suspend"){
         return   <> 
            <div style={{color:"white"}} className='mt'>
               آیا از تعلیق چت اطمینان دارید؟
            </div>
            <div style={{color:"white"}} className='mt'>
               در صورت تعلیق چت تا زمانی که کاربر پیام جدیدی ارسال نکند
               این چت در قسمت چت های من قابل مشاهده نیست
            </div>
            <div className='dfjc mt'>
              <button  onClick={()=>{
                  setModal(false)
                  setFile([])
              }} className='btnreply'> انصراف </button>
              <button onClick={()=>{
                 load(true)
                 axios.post(url+"api/v1/panel/chat/suspended/"+selectedChat.id,{},
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                 } }).then(res=>{
                          console.log(res.data);
                           setActiver({ a1: "active", a2: "", a3: "", a4: "" })
                           setModal(false)
                           NotificationManager.success("چت با موفقیت تعلیق شد")
                           setSelectedChat(null)
                           setTyping(null)
                           setReply(null)
                           setFile([])
                           socket.emit("getOpenChats")
                           load(false)
                
                }).catch(err=>{
                load(false)
                console.log(err);
                 })
                 
              }} className='btnreply'> تعلیق </button>
            </div>
         </>
        }else if(file[1]==="dataes"){
            const stat = (s)=>{
                if(s===1){
                  return "فعال"
                }else{
                  return "غیر فعال"
                }
              }
              const fileShow = (rep)=>{
                if(rep.file !== null){
                  if(/\.(jpg|jpeg|png|webp|avif|gif)$/.test(url+"storage/"+rep.file)){
                      return <img onClick={()=>{
                          window.open(url+"storage/"+rep.file, "_blank") 
                 }} className='tickImage' src={url+"storage/"+rep.file} alt="ticketFile"/>
                  }else{
                      return <a style={{color:"blue"}}
                      href={url+"storage/"+rep.file} target='_blank' 
                      rel='noreferrer'>برای دانلود فایل آپلود شده کلیک نمایید</a>
                  }
                }else{
                  return null
                }
              }
              const authType = (a)=>{
                if(a === "Phone_auth"){
                    return "احراز هویت شماره همراه انجام شده"
                }else if(a === "Email_auth"){
                    return "احراز هویت ایمیل انجام شده"
                }else if(a==="Identity_auth"){
                    return "احراز هویت اطلاعات هویتی انجام شده"
                }else if(a === "IdCard_auth"){
                    return "احراز هویت تصویر کارت ملی انجام شده"
                }else if(a=== "Selfimage_auth"){
                    return "احراز هویت تصویر سلفی با کارت ملی انجام شده"
                }
             }
          return  <div className='chatTable userDetail' style={{minWidth:"640px" ,padding:"10px",color:"white"}}>
              <div className='bB p5'> اطلاعات کاربر {userData.username} : </div>
                    <div className='orderDetail bb'>
                        <div className='fl' style={{padding:"5px"}}>  نام و نام خانوادگی </div>
                        <div  style={{padding:"5px"}}> {userData.first_name+ " " + userData.last_name} </div>
                    </div>
                    <div style={{textAlign:"right" , padding:"10px",color:"white"}} className=' bb'>
                        <div className='fl' >  حساب ها : {" "}  
                             <CiCircleChevDown style={{
                                fontSize: "25px",
                                verticalAlign:"middle",
                                cursor:"pointer"
                            }} onClick={()=>{
                                if(dis.a1 === ""){
                                    setDis({ a1: "none", a2: dis.a2 , a3: dis.a3 ,a4:dis.a4})
                                  }else{
                                    setDis({ a1:"" , a2: dis.a2 , a3: dis.a3 ,a4:dis.a4})
                                  }
                            }}/> </div>
                        <table style={{display:dis.a1 , marginTop:"10px" , color:"white" }} className="table table-hover  " border={1}>
                        <thead>
                           <tr>
                             <th> نوع حساب</th>
                             <th> وضعیت </th>
                             <th> توسط </th>
                             <th> ورود</th>
                             <th> مرحله </th>
                           </tr>
                        </thead>
                        <tbody>
                           {userData.accounts.map(d=>{
                            return <tr key={d.id}>
                                <td> {d.order.product.type +
                                 " | "+d.order.product.title+" | "+d.order.broker.name}  
                                 </td>
                                 <td> {stat(d.status)} </td>
                                 <td> {d.created_with === "user"?
                                   "کاربر" : "فنفیکس"
                                } </td>
                                 <td style={{direction:"ltr",textAlign:"left"}}> {d.login}  </td>
                                 
                                 <td> {d.type} </td>
                                 
                                 </tr>
                             })} 
                            </tbody>
                        </table>
                    </div>
                    <div style={{textAlign:"right" , padding:"10px"}} className=' bb'>
                        <div className='fl'> تیکت های کاربر : {" "}  
                             <CiCircleChevDown style={{
                                fontSize: "25px",
                                verticalAlign:"middle",
                                cursor:"pointer"
                            }} onClick={()=>{
                              if(dis.a2 === ""){
                                setDis({ a1: dis.a1, a2: "none" , a3: dis.a3 ,a4:dis.a4})
                              }else{
                                setDis({ a1: dis.a1, a2: "" , a3: dis.a3,a4:dis.a4 })
                              }
                            }}/>  </div>
                        <div> <table style={{display:dis.a2 , marginTop:"10px" , color:"white" }} className="table table-hover  " border={1}>
                        <thead>
                           <tr>
                             <th> عنوان تیکت </th>
                             <th> دپارتمان </th>
                             <th> پاسخ توسط </th>
                             <th> وضعیت </th>
                             <th> تاریخ </th>
                           </tr>
                        </thead>
                        <tbody>
                               {userData.tickets.map(d=>{
                            return <>
                            <tr key={d.id}>
                                <td> {d.title}  
                                 </td>
                                 <td> {d.department === 1?"پشتیبانی عمومی":
                                    "پشتیبانی فنی"} </td>
                                 <td> {d.responder !== null?
                                  d.responder.first_name+" "+d.responder.last_name
                                 :"ندارد"} </td>
                                 <td> {d.status === 2 ? "بسته": "باز"}  </td>
                                 <td> {d.created_at} </td>
                                 <td > 
                                <MdAdsClick style={{
                                    verticalAlign:"middle",
                                    fontSize:"23px"
                                }} onClick={() => {
                                    
                                    if(classs1[0]){
                                        setClass1([false])
                                        console.log(d);
                                        
                                    }else{
                                        setClass1([true,d])
                                        console.log(d);
                                    }
                                 }} /> </td>
                                 </tr>
                                 {classs1[0] && classs1[1].id === d.id? 
                                 <tr>
                                   <th style={{background:"#00000096"}} colSpan={7}>
                                   <li style={{marginBottom:"10px"}} className="tmln__item tmln__item--bg-dark">
				                    <span > <BsTicketPerforatedFill /> تیکت  {" "} {d.created_at} توسط {userData.username} ایجاد شده </span>
				                    <span > برای دپارتمان {d.department === 2 ? " فنی ":" پشتیبانی "} </span>
                                    <p className="tmln__item-headline">  <TiTick/> عنوان تیکت -- {d.title}  </p>
				                    <div className='tikcontent'>
				                       <p> {d.description} </p>  
                                       {fileShow(d)}
                                    </div>
			                       </li>
                                       {d.replies.map(dd=>{
                                            if(dd.reply_type === "admin"){
                                                return <li   className="tmln__item tmln__item--bg-lght">
                                                <span > <MdSupportAgent /> پاسخ تیکت {dd.created_at} توسط دپارتمان {d.department === 2 ? " فنی ":" پشتیبانی "}  : </span>
                                                <p className='answer' style={{background:"white" , color:"black"}}> {dd.description} </p>
                                            </li>
                                             }else{
                                                 return <li className="tmln__item tmln__item--bg-dark">
                                                <span > <FaUser /> پاسخ کاربر {" "+userData.username+" در"}  {dd.created_at} : </span>
                                                
                                                <p className='tikcontent'>{dd.description}</p>
                                                
                                            </li>}
                                        })
                                    }
                                   </th>
                                 </tr>
                                 :null}
                            </>
                          })} 
                            
                         
                        </tbody>
                        </table> 
                        </div>
                    </div>
                    <div style={{textAlign:"right" , padding:"10px" , color:"white"}} className=' bb'>
                        <div className='fl'> احراز هویت های کاربر : {" "}  
                             <CiCircleChevDown style={{
                                fontSize: "25px",
                                verticalAlign:"middle",
                                cursor:"pointer"
                            }} onClick={()=>{
                              if(dis.a3 === ""){
                                setDis({ a1: dis.a1, a2: dis.a2 , a3:  "none" ,a4:dis.a4})
                              }else{
                                setDis({ a1: dis.a1, a2: dis.a2 , a3: "" ,a4:dis.a4 })
                              }
                            }}/>  </div>
                        <div> <table style={{display:dis.a3 , marginTop:"10px" , color:"white" }} className="table table-hover  " border={1}>
                        <thead>
                           <tr>
                             <th> احراز هویت تکمیل شده </th>
                             <th> تاریخ انجام </th>
                           </tr>
                        </thead>
                        <tbody>
                            
                               {userData.confirmed_authentications.map(d=>{
                                 return <tr key={d.id}>
                                           <td> {authType(d.type)}  
                                            </td>
                                            <td> {d.date} 
                                            </td>
                                        </tr>
                          })} 
                            
                         
                        </tbody>
                        </table> 
                        </div>
                    </div>
                
                    <div style={{textAlign:"right" , padding:"10px",color:"white"}} className=' bb'>
                        <div className='fl'> افراد دعوت شده توسط کاربر : </div>
                        {userData.all_referrals.map(d=>{
                            return <div className='f12' style={{margin:"10px"}} 
                            key={d.id}>{d.first_buy?<TiTickOutline style={{verticalAlign:"middle" , fontSize:"25px",color:"green"}} />:
                             <GiCancel style={{verticalAlign:"middle" , fontSize:"20px" , color:"red"}}/>}  {d.username} </div>
                        })}
                    </div>
              </div>
        }else{
            return <>
            <div> فرمت فایل ارسالی صحیح نمی باشد </div>
            
            <div> 
               
               <button onClick={()=>{
                   setModal(false)
                   setFile([])
               }}> فهمیدم </button>
            </div>
           </>
        }
      }
    const sendMessage = () => {
        if (message.trim() === '') return; // Prevent sending empty messages
        socket.emit('getUnreadCounts' , selectedChat.user_id , "newMessage")
        const senderType = 'support'; // Hardcoded for simplicity
        if(reply.length!==0){
          if(edit){
            socket.emit("editMessage",reply[0].id,message)
            setEdit(false)
            setReply([])
          }else{
          socket.emit('sendMessage', selectedChat.id, message,
          user.dataes.id, senderType , "message",reply[0].id);
          setReply([])
          }
          
          
        } else{
          socket.emit('sendMessage', selectedChat.id, message, user.dataes.id, senderType , "message");
          
        }
        socket.emit('sendChat', selectedChat.id, message);
        setMessage('');
    };
    const [activer , setActiver] = useState({a1:"active",a2:"", a3:"",a4:"",a5:""})
 
    const fetchQuickResponses = async () => {
        load(true)
        try {
          const response = await axios.get("https://crm.fenefx.net/api/quick"); // Replace with your actual API
          setQuickResponse(response.data); // Store the fetched data
          setQuickResponseMain(response.data); 
          load(false)
        } catch (error) {
          console.error( error);
          load(false)

        }
      };
      const convertTextToLinks = (text) => {
        // Regular expression to detect URLs (http/https/ftp)
        const urlRegex = /\b(https?:\/\/[^\s]+(?:\/[^\s]*)?)/gi;
      
        return text.split(urlRegex).map((part, index) => {
          // If this part is a URL, render it as an anchor tag
          if (urlRegex.test(part)) {
            return (
              <a style={{
                display:"inline-block",
                direction:"ltr",
                color:"darkblue"
              }} key={index} href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            );
          }
          // If it's not a URL, render the text as-is
          return part;
        });
      };
    const tabBtn = ()=>{
        if(activer.a1 ){
            if(chats.find(e=>e.id === selectedChat.id)){
              return <>
              
              <div className={reply[0]?"replyMessage show":"replyMessage h0px"} 
              style={{
              width:"100%",
              background:"rgba(255, 255, 255, 0.81)",
              color:"black",
              direction:"rtl"}}>
                 <div style={{
                   borderBottom:"1px solid black"
                  ,display:"flex"
                  ,justifyContent:"space-between"
                  ,marginBottom:"5px"}}>
                 
                  <div style={{
                  paddingBottom:"5px",
                  direction:"rtl",
                  }}> {edit?"ویرایش پیام :":"پاسخ برای :"}</div>
                  <MdCancel onClick={()=>{
                     setReply([])
                     setEdit(false)
                 }} style={{fontSize:"20px"}} />
                 </div>
                 <div>{reply[0]&& truncateText(reply[0].message,50)} </div>
              </div>
            <div className="input-group-prepend">
                 {quickResponse.length!==0?
               <div
                 className='qrCon'
                 style={{
                   position: 'absolute',
                   top: '-300px', 
                   maxHeight: '300px',
                   overflowY: 'scroll',
                   border: '1px solid #ccc',
                   width: '100%',
                   zIndex: 1, 
                   borderRadius:"10px",
                   height:"68vh"
               }}
               >
          {quickResponse.map((item, index) => (
            <ul style={{listStyle:"none" , whiteSpace:"preserve-breaks"}} key={index}>
            <div style={{textAlign:"center",
                background:"#ffffff52",
                padding:"10px",
                border:"1px solid white"
                }}>{item.title}</div>
            {item.texts.map(d=>{
               return <li key={d.id} style={{ 
               padding: '5px' , 
               marginBottom:"10px",
               border:"1px solid white",
               borderRadius:"5px",
               textAlign:"right",
               direction:"rtl",
               background:"black",
               margin:"10px",
               fontSize:"12px",
               cursor:"pointer"
               }}
               onClick={()=>{
                setMessage(d.text)
                setQuickResponse([])
               }}
               >
                          {d.text}
                      </li>
            })}
            </ul>
          ))}
        </div>:null}
            
            <span style={{
                zIndex:"8",
                height:"100%",
                cursor:"pointer",
                borderRadius:"5px 0px 0px 5px"
            }} onClick={sendMessage} className="input-group-text"><BsFillSendFill /></span>
        </div>
        
        <textarea onKeyDown={handleKeyDown} style={{border:"0" , color:"white" ,
        fontSize:"13px",
        background:"#ffffff4f"}} onChange={(e) => {
          if(e.target.value[0] === "/"){
            const value = e.target.value;
            
            const query = value.split('/').pop().trim();
            
            if (query.length > 0) {
              if(query[0] === "+"){
                const queryInside = value.split('/+').pop().trim();

                // Create an array to store all matching messages
                const allMatchingMessages = [];
          
                // Assuming quickResponse contains items with a 'messages' array
                quickResponseMain.forEach(item => {
                  const matchingMessages = item.texts.filter(message =>
                    message.text.toLowerCase().includes(queryInside.toLowerCase())
                  );
                  
                  // Push all matching messages to the array
                  allMatchingMessages.push(...matchingMessages);
                });
          
                // Log the array of all matching messages
                if(allMatchingMessages.length>0){
                  setQuickResponse([{title:"جستجو های شما" ,id:0 , texts:allMatchingMessages}])
                }else{
                  fetchQuickResponses();
                }
                
              } else {
                const filtered = quickResponseMain.filter((item) =>
                  item.title.toLowerCase().includes(query.toLowerCase())
                );
                if (filtered.length === 0) {
                  fetchQuickResponses();
                }
                setQuickResponse(filtered);
              }
            } else {
              fetchQuickResponses(); 
            }
          
          } else {
            setQuickResponse([]);
          }
          
            setMessage(e.target.value)}}
        value={message} 
        type="text" className="form-control" placeholder="پیام خود را وارد نمایید..."/></>
            }else{
             return <div style={{textAlign:"center",color:"white" , 
             padding:"5px",width:"100%",background:"#00b52d94"}}>
                    چت توسط کاربر بسته شده
             </div>
            }
        }else if(activer.a2){
          if(chats.find(e=>e.id === selectedChat.id)){
            if(chats.find(e=>e.id === selectedChat.id && e.support_id ===null)){
            return <>
                   <button onClick={()=>{
                     setFile(["","pick"])
                     setModal(true)
                     }} style={{width:"100%",border:"0"}} className='btnreply'> انتخاب چت </button>
                   </>
            }else{
              return <div style={{textAlign:"center",color:"white" , padding:"5px",
              width:"100%",background:"#00b52d94"}}>
             چت برداشته شد 
             </div>
            }
            
          }else{
           return <div style={{textAlign:"center",color:"white" , 
           padding:"5px",width:"100%",background:"#00b52d94"}}>
              چت توسط کاربر بسته شد
             </div>
          }
        }else if(activer.a3){
          if(chats.find(e=>e.id === selectedChat.id)){
            return <div style={{textAlign:"center",color:"white" , 
            padding:"5px",width:"100%",background:"#00b52d94"}}>
             چت توسط {selectedChat.support&&" "+selectedChat.support.name+" "} برداشته شده 
             </div>
          }else{
            return <div style={{textAlign:"center",color:"white" , 
            padding:"5px",width:"100%",background:"#00b52d94"}}>
             چت توسط {selectedChat.support&&" "+selectedChat.support.name+" "} تعلیق شد یا توسط کاربر بسته شد
             </div>
          }
          
        }
    }
    const withChat = ()=>{
        if(activer.a5){
           return personsChat
        }else{
          if(chatsFiltered.length===0){
            return chats
        }else{
            return chatsFiltered
        }
        }
    }
    const seter = (e)=>{
                console.log(e);
      
                setReply([])
                setEdit(false)
                setSelectedChat(null)
                setTyping(null)
                setActiver({a1:"",a2:"" , a3:"",a4:"",a5:"active"})
                setPersonsChat(e)
    }
    return (
      <div >
        <input accept=".zip,image/*" type='file'ref={refImage} hidden onChange={(e)=>{
                        const file = e.target.files[0];
                        if (file) {
                          const fileType = file.type;
                          const fileName = file.name;
                          console.log(fileType , fileName , fileName.endsWith('.zip'));
                          if (fileType === 'application/zip' || fileName.endsWith('.zip')) {
                            setFile([e.target.files[0],"file" , fileName])
                             setModal(true)

                          } else if (fileType.startsWith('image/')) {
                            setFile([e.target.files[0],"image"])
                            setModal(true)
                            
                          } else {
                            setFile([e.target.files[0],"nothing"])
                            setModal(true)
                            
                            
                          }
                        }
                        
                    }}/>
        <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                setActiver({a1:"active",a2:"",a4:"",a3:"",a5:""})
                    setReply([])
                    setEdit(false)
                    setSelectedChat(null)
                setTyping(null)
                   
            }} className={activer.a1}>
              چت های من 
              <span style={{color:"white"}} className='newCount'>
              {chats.filter(d => d.support_id === user.dataes.id).length}
              </span>
            </a>
            <a onClick={()=>{
                setActiver({a1:"",a2:"active" , a3:"",a4:"",a5:""})
                setSelectedChat(null)
                setTyping(null)

                   setReply(null)
            }} className={activer.a2}>
              در انتظار 
              <span style={{borderColor:"red",
                boxShadow:"0px 0px 5px #ff1818",
                background:"none",
                color:'white'
              }} className='newCount'>
                {chats.filter(d => d.support_id === null || d.support_id === undefined).length }
              </span>
            </a>
        <a onClick={()=>{
          setReply([])
          setEdit(false)
            setSelectedChat(null)
            setTyping(null)

                setActiver({a1:"",a2:"" , a3:"active",a4:"",a5:""})
                
            }} className={activer.a3}>
              چت های دیگر 
              <span style={{color:"white"}} className='newCount'>
              {chats.filter(d => d.support_id !== null && d.support_id !== user.dataes.id).length}
              </span>
            </a>
            {user.dataes.permissions.find(e=>e.name === "see_chats")&&<><a onClick={()=>{
          setReply([])
          setEdit(false)
            setSelectedChat(null)
            setTyping(null)

                setActiver({a1:"",a2:"" , a3:"",a4:"active",a5:""})
                
            }} className={activer.a4}>
              پنل ادمین 
             
            </a>
            <a onClick={()=>{
                setTyping(null)
                setReply([])
                setEdit(false)
                setSelectedChat(null)
                setActiver({a1:"",a2:"" , a3:"",a4:"",a5:"active"})
                
            }} className={activer.a5}>
               
             بررسی چت ها
            </a>
            </>}
        </div>
      </div>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.0/dist/css/bootstrap.min.css" rel="stylesheet"></link>
    {activer.a4?
      <>
        <AdminChatPanel setPerson={seter}/>
      </>
      :
     <div style={{marginLeft:"15px"}}>
     <div className="row clearfix">
         <div style={{paddingLeft:"0px"}} className="col-lg-12">
             <div className="card2 chat-app">
                 <div id="plist" className={isOpen?"people-list open":"people-list"}>
                     <div style={{direction:"ltr",marginTop:"9px",marginBottom:"14px"}} className="input-group">
                         <div style={{cursor:"pointer"}} onClick={handleSearch} className="input-group-prepend">
                             <span style={{height:"100%"}} className="input-group-text">
                                 <IoIosSearch />
                             </span>
                         </div>
                             {chatsFiltered.length!==0?
                          <div style={{cursor:"pointer"}} className="input-group-prepend" onClick={()=>{
                             setChatsFiltered([])
                             console.log(chatsFiltered);
                             
                         }} >  
                             <span style={{height:"100%"}} className="input-group-text">
                                 <MdCancel />
                             </span>
                             </div> :null}
                         
                         <input 
                         style={{background:"#ffffff4f" , color:"white"}} 
                         value={inputValue}
                         onChange={(e)=>{
                           setInputValue(e.target.value)
                         }}
                         type="text" 
                         className="form-control" 
                         placeholder="جستجو..."
                         />
                     </div>
                     <ul className="allChats list-unstyled chat-list mt-2 mb-0">
                         {withChat().map(d=>{
                             if(activer.a3){
                                 if(d.support_id!==null && d.support_id!==user.dataes.id ){
                                   return <li onClick={() => { 
                                         setTyping(null)
                                         setMessages([])
                                         setReply([])
                                         setEdit(false)
                                         setSelectedChat(d)
                                         }} key={d.id} className={selectedChat!==null && selectedChat.id===d.id?"clearfix selected":
                                           "clearfix"
                                         }>
                                      <div style={{position:"relative"}}>
                                         <img src={d.user!==null&&d.user!==undefined?url+"storage/"+d.user.image
                                           :userImage} alt="avatar"/>
                                           {d.unread_count!==0&&<div style={{
                                             background:"red",
                                             borderRadius:"50%",
                                             fontSize:"10px",
                                             width:"20px",
                                             height:"20px",
                                             display:"flex",
                                             justifyContent:"center",
                                             alignItems:"center",
                                             position:"absolute",
                                             left:"34px",
                                             color:"white"
                                            }}>{d.unread_count}</div>} 
                                         
                                      </div>
                                      <div className="about ">
                                          <div className="name ">{d.user!==null&&d.user!==undefined?d.user.username:d.user_id}</div>
                                          <div style={{marginTop:"3px"}} className="name">
                                          {d.user!==null&&d.user.name!==null?
                                           d.user.name.replaceAll("null", ""):null}
                                           <span style={{padding:"1px 5px", fontSize:"8px",marginRight:"2px"}}
                                            className={d.is_user_online?"green":"red"}>{d.is_user_online?" آنلاین ":" آفلاین " }
                                           </span>
                                            </div>
                                          <div style={{display:"flex",gap:"5px",alignItems:"center"}} className="status f12"> 
                                           <div>{d.latest_message?
                                           d.latest_message_time + " - "+truncateText(d.latest_message,15):
                                           d.updated_at
                                           }</div> 
                                          </div>
                                      </div>
                                    </li>
                                 }
                                 }else if(activer.a1){
                                    if(d.support_id === user.dataes.id){
                                     return <li onClick={() =>{
                                       setTyping(null)
                                       setMessages([])
                                         if( selectedChat===null||d.id !== selectedChat.id ){
                                           socket.emit('readMessage', d.id  )
                                           socket.emit('markMessagesSeen', d.id ,user.dataes.id )
                                           socket.emit('getOpenChats')
                                         }
                                         setReply([])
                                         setEdit(false)
                                         setSelectedChat(d)}} key={d.id} 
                                         className={selectedChat!==null && selectedChat.id===d.id?"clearfix selected":
                                           "clearfix"
                                         }>
                                       <div style={{position:"relative"}}> <img src={d.user!==null&&d.user!==undefined?url+"storage/"+d.user.image
                                        :userImage} alt="avatar"/>
                                         {d.unread_count!==0&&<div style={{
                                             background:"red",
                                             borderRadius:"50%",
                                             fontSize:"10px",
                                             width:"20px",
                                             height:"20px",
                                             display:"flex",
                                             justifyContent:"center",
                                             alignItems:"center",
                                             position:"absolute",
                                             left:"34px",
                                             color:"white"
                                            }}>{d.unread_count}</div>} 
                                      </div>  
                                      <div className="about ">
                                          <div className="name ">{d.user!==null&&d.user!==undefined?d.user.username:d.user_id}</div>
                                          <div style={{marginTop:"3px"}} className="name">
                                          {d.user!==null&&d.user.name!==null?
                                           d.user.name.replaceAll("null", ""):null}
                                           <span style={{padding:"1px 5px", fontSize:"8px",marginRight:"2px"}}
                                            className={d.is_user_online?"green":"red"}>{d.is_user_online?" آنلاین ":" آفلاین " }
                                           </span>
                                           </div>
                                          <div style={{display:"flex",gap:"5px",alignItems:"center"}} className="status f12"> 
                                           <div>{d.latest_message?
                                           d.latest_message_time + " - "+truncateText(d.latest_message,15):
                                           d.updated_at
                                           }</div> 
                                          </div>
                                      </div>
                                    </li>
                                 }
                     }else if(activer.a2){
                                 if(d.support_id === null || d.support_id === undefined){
                                     return <li onClick={() => {
                                      setTyping(null)
                                      setMessages([])
                                       setReply([])
                                       setEdit(false)
                                         setSelectedChat(d)}} key={d.id} 
                                         className={selectedChat!==null && selectedChat.id===d.id?"clearfix selected":
                                           "clearfix"
                                         }>
                                      <div style={{position:"relative"}}> <img src={d.user!==null&&d.user!==undefined?url+"storage/"+d.user.image
                                        :userImage} alt="avatar"/>
                                         {d.unread_count!==0&&<div style={{
                                             background:"red",
                                             borderRadius:"50%",
                                             fontSize:"10px",
                                             width:"20px",
                                             height:"20px",
                                             display:"flex",
                                             justifyContent:"center",
                                             alignItems:"center",
                                             position:"absolute",
                                             left:"34px",
                                             color:"white"
                                            }}>{d.unread_count}</div>} 
                                      </div>  
                                      <div className="about ">
                                          <div className="name ">{d.user!==null&&d.user!==undefined?d.user.username:d.user_id}</div>
                                          <div style={{marginTop:"3px"}} className="name">
                                          {d.user!==null&&d.user.name!==null?
                                           d.user.name.replaceAll("null", ""):null}
                                           <span style={{padding:"1px 5px", fontSize:"8px",marginRight:"2px"}}
                                             className={d.is_user_online?"green":"red"}>{d.is_user_online?" آنلاین ":" آفلاین " }
                                           </span>
                                          </div>
                                          <div style={{display:"flex",gap:"5px",alignItems:"center"}} className="status f12"> 
                                           <div>{d.latest_message?
                                           d.latest_message_time + " - "+truncateText(d.latest_message,15):
                                           d.updated_at
                                           }</div> 
                                          </div>
                                      </div>
                                    </li>
                                 }
                             }else if (activer.a5){
                              return <li onClick={() => { 
                                setTyping(null)
                                setMessages([])
                                setReply([])
                                setEdit(false)
                                setSelectedChat(d)
                                }} key={d.id} className={selectedChat!==null && selectedChat.id===d.id?"clearfix selected":
                                  "clearfix"
                                }>
                             <div style={{position:"relative"}}>
                                <img src={d.user!==null&&d.user!==undefined?url+"storage/"+d.user.image
                                  :userImage} alt="avatar"/>  
                             </div>
                             <div className="about ">
                                 <div className="name ">{d.user!==null&&d.user!==undefined?d.user.username:d.user_id}</div>
                                 <div style={{marginTop:"3px"}} className="name">
                                 {d.user!==null&&d.user.name!==null?
                                  d.user.name.replaceAll("null", ""):null}
                                  <span style={{padding:"1px 5px", fontSize:"8px",marginRight:"2px"}}
                                   className={d.is_user_online?"green":"red"}>{d.is_user_online?" آنلاین ":" آفلاین " }
                                  </span>
                                   </div>
                                 <div style={{display:"flex",gap:"5px",alignItems:"center"}} className="status f12"> 
                                  <div>{
                                  moment(d.updated_at ).format('jYYYY/jM/jD HH:mm:ss')
                                  }</div> 
                                 </div>
                             </div>
                           </li>
                             }
                         })}
                     </ul>
                 </div>
                 {selectedChat ? <div key={selectedChat.id} className="chat">
                     <div className="chat-header clearfix">
                         <div className="row">
                             <div style={{
                                 display:"flex",
                                 alignItems:"center"
                             }} className="col-lg-6">
                                 <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                 <img src={selectedChat.user!==null&&selectedChat.user!==undefined?
                                 url+"storage/"+selectedChat.user.image
                                      :userImage} alt="avatar"/>
                                 </a>
                                 <div className="chat-about">
                                         <div style={{margin:"0px",fontSize:"12px"}} >
                                         {selectedChat.user!==null&&selectedChat.user!==undefined?
                                          selectedChat.user.username:selectedChat.user_id}</div>
                                          <div className="name f12">
                                           {selectedChat.user!==null&&selectedChat.user.name!==null?
                                             selectedChat.user.name.replaceAll("null", ""):null}
                                          </div>
                                     
                                     
                                     <div className="name f12">{activer.a5?
                                     moment(selectedChat.created_at).format('jYYYY/jM/jD HH:mm:ss'): selectedChat.created_at}</div>
                                 </div>
                             </div>
                             <div className="widzad col-lg-6 hidden-sm text-right">
                                   <a onClick={()=>{
                                    socket.emit("getOpenChats")
                                   }} style={{
                                    padding:"10px" 
                                    }} href="javascript:void(0);" className="btn-outline-info">
                                     <TbRefresh style={{fontSize:"30px",verticalAlign:"middle" }} />
                                   </a>
                                 {activer.a1&&<a style={{
                                  padding:"10px" 
                                  }} onClick={()=>{
                                     setFile(["","suspend"])
                                     setModal(true)
                                  }}  href="javascript:void(0);" className="btn-outline-danger">
                                 <IoIosCloseCircle style={{fontSize:"32px",
                                     verticalAlign:"middle"  }} />
                                 </a>}
                                 {selectedChat.user!==null&&selectedChat.user!==undefined&&
                                 <a style={{
                                  padding:"10px" 
                                  }} href="javascript:void(0);" className="btn-outline-light"
                                  onClick={()=>{
                                     load(true)
                                     axios.get(url+'api/v1/panel/chat/user/'+selectedChat.user_id,
                                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                         }})
                                         .then(response => {
                                             console.log(response.data);
                                             setFile(["","dataes"])
                                             setUserData(response.data)
                                             setModal(true)
                                             load(false)
                                         })
                                         .catch(err => {
                                             console.error('Error fetching chats:', err);
                                             load(false)
                                             
                                         });
                                  }}
                                  >
                                   <FaUserGear style={{fontSize:"30px",verticalAlign:"middle" }} />
                                 </a>}
                                 {selectedChat.user!==null&&selectedChat.user!==undefined&&<a 
                                  onClick={()=>{
                                     let userD = selectedChat.user
                                     if(user.dataes.roles[0].name==="admin"){
                                         load(true)
                                         
                                     axios.post(url+"api/v1/auth/support/login",{email: userD.username},
                                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                     } }).then(res=>{
                                         load(false)
                                         Cookies.set("support_token" , Cookies.get('token'))
                                         Cookies.set("token" , res.data.access_token)
                                         Cookies.set("username" , userD.username)
                                         window.location.reload()
                                     }).catch(err=>{
                                         load(false)
                                         console.log(err);
                                     })
                                     }else{
                                         load(true)
                                     axios.post(url+"api/v1/panel/admin/login",{id: userD.id},
                                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                     } }).then(res=>{
                                       load(false)
                                       Cookies.set("support_token" , Cookies.get('token'))
                                       Cookies.set("token" , res.data.access_token)
                                       Cookies.set("username" , userD.username)
                                       window.location.reload()
                                     }).catch(err=>{
                                         load(false)
                                         console.log(err);
                                     })
                                     }
                                  }}
                                  style={{
                                  padding:"10px" 
                                  }} href="javascript:void(0);" className="btn-outline-warning">
                                   <FaSolarPanel style={{fontSize:"30px",verticalAlign:"middle" }} />
                                 </a>}
                                 {activer.a1&&<><a onClick={()=>{
                                     refImage.current.click()
                                 }} style={{
                                  padding:"10px" 
                                  }} href="javascript:void(0);" className="btn-outline-primary">
                                   <FaCamera style={{fontSize:"30px",verticalAlign:"middle" }} />
                                 </a>
                                 <a onClick={()=>{
                                     refImage.current.click()
                                 }} style={{
                                  padding:"10px" 
                                  }} href="javascript:void(0);" className="btn-outline-success">
                                   <FaRegFileAlt style={{fontSize:"30px",verticalAlign:"middle" }} />
                                 </a></>}
                                 
                             </div>
                         </div>
                     </div>
                     <div ref={messageEndRef} className="chat-history">
                         <ul className="m-b-0">
                         {messages.map(msg =>{
                             if(msg.type === "support"){
                                 if(msg.message_type ==="message"){
                                   return <> <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                   <div style={{direction:"rtl"}} className="message-data text-right">
                                     <img src={url+"storage/" + user.dataes.image} alt="avatar"/>
                                     <span  className="message-data-time">
                                      {msg.created_at}
                                      {" توسط "+msg.first_name+" "+
                                      msg.last_name+" "}
                                      {msg.read_status?<FaEye  style={{
                                     color:"white",
                                     verticalAlign:"middle", 
                                     fontSize:"25px",
                                     marginRight:"10px"
                                     }}/>:null}  </span>

                                    </div>
                                    <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                                      <div className="message other-message float-right"> {convertTextToLinks(msg.message)} </div>
                                      <div style={{display:"grid"}}>
                                      <FaReply onClick={()=>{
                                       replyHandle(msg)
                                      }} style={{
                                      color:"white",
                                      fontSize:"20px" ,
                                      verticalAlign:"end",
                                      textAlign:"right",
                                      transform:"scaleX(-1)"}}/>
                                      <FiEdit
                                      onClick={()=>{
                                       setEdit(true)
                                       setMessage(msg.message)
                                       setReply([msg])
                                      }}
                                      style={{
                                       color:"white",
                                       fontSize:"20px" ,
                                       verticalAlign:"end",
                                       textAlign:"right",
                                       marginTop:"10px",
                                       transform:"scaleX(-1)"}}
                                      />
                                       </div>
                                     </div>
                                     {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                     <div style={{ marginTop:"5px", display:"flex", gap:"5px"}}>
                                     <FaArrowTurnUp style={{fontSize:"30px", color:"white"}}/>
                                     <div onClick={()=>{
                                       scrollToDiv(msg.reply_to)
                                     }} style={{
                                       padding:"10px",
                                       borderRadius:"10px",
                                       background:"#ffffffc4",
                                       color:"black",
                                       fontSize:"11px",
                                       maxWidth:"50%",
                                       textAlign:"right"
                                     }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                   </div>
                                     }
                                   </li>
                                   
                                  </> 
                                 }else if(msg.message_type ==="file"){
                                      return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                     <div style={{direction:"rtl"}} className="message-data text-right">
                                       <img src={url+"storage/" + user.dataes.image} alt="avatar"/>
                                       <span  className="message-data-time">{msg.created_at}{msg.read_status?<FaEye  style={{
                                     color:"white",
                                     verticalAlign:"middle", 
                                     fontSize:"25px",
                                     marginRight:"10px"
                                     }}/>:null} </span>
                                      </div>
                                      <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                                     <div className="message other-message float-right"> 
                                       <a href={msg.message} target='_blank' rel="noreferrer" >
                                          برای دانلود فایل کلیک نمایید
                                       </a>
                                     </div>
                                     <div style={{display:"grid"}}>
                                      <FaReply onClick={()=>{
                                       replyHandle(msg)
                                      }} style={{
                                      color:"white",
                                      fontSize:"20px" ,
                                      verticalAlign:"end",
                                      textAlign:"right",
                                      transform:"scaleX(-1)"}}/>
                                      <FiEdit
                                      onClick={()=>{
                                       setEdit(true)
                                       setReply([msg])
                                      }}
                                      style={{
                                       color:"white",
                                       fontSize:"20px" ,
                                       verticalAlign:"end",
                                       textAlign:"right",
                                       marginTop:"10px",
                                       transform:"scaleX(-1)"}}
                                      />
                                       </div>
                                     </div>
                                     {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                     <div style={{ marginTop:"5px", display:"flex", gap:"5px"}}>
                                     <FaArrowTurnUp style={{fontSize:"30px", color:"white"}}/>
                                     <div onClick={()=>{
                                       scrollToDiv(msg.reply_to)
                                     }} style={{
                                       padding:"10px",
                                       borderRadius:"10px",
                                       background:"#ffffffc4",
                                       color:"black",
                                       fontSize:"11px",
                                       maxWidth:"50%",
                                       textAlign:"right"
                                     }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                   </div>
                                     }
                                    </li> 
                                 }else if(msg.message_type ==="image"){
                                     return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                       
                                     <div style={{direction:"rtl"}} className="message-data text-right">
                                       <img src={url+"storage/" + user.dataes.image} alt="avatar"/>
                                       <span  className="message-data-time">
                                         {msg.created_at}
                                         {msg.read_status?<FaEye  style={{
                                     color:"white",
                                     verticalAlign:"middle", 
                                     fontSize:"25px",
                                     marginRight:"10px"
                                     }}/>:null} </span>
                                      </div>
                                     <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                                      <div style={{textAlign:"center"}} className="message other-message float-right"> 
                                         <img onClick={()=>{
                                         window.open(msg.message,"_blank")
                                         }} src={msg.message} 
                                         style={{maxHeight:"200px",maxWidth:"200px"}}
                                         alt='sendedImage' />
                                      </div>
                                      <div style={{display:"grid"}}>
                                      <FaReply onClick={()=>{
                                       replyHandle(msg)
                                      }} style={{
                                      color:"white",
                                      fontSize:"20px" ,
                                      verticalAlign:"end",
                                      textAlign:"right",
                                      transform:"scaleX(-1)"}}/>
                                       </div>
                                     </div>
                                     {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                     <div style={{ marginTop:"5px", display:"flex", gap:"5px"}}>
                                     <FaArrowTurnUp style={{fontSize:"30px", color:"white"}}/>
                                     <div onClick={()=>{
                                       scrollToDiv(msg.reply_to)
                                     }} style={{
                                       padding:"10px",
                                       borderRadius:"10px",
                                       background:"#ffffffc4",
                                       color:"black",
                                       fontSize:"11px",
                                       maxWidth:"50%",
                                       textAlign:"right"
                                     }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                   </div>
                                     }
                                    </li> 
                                 }
                                 
                                }else if(msg.type === "user"){
                                 if(msg.message_type ==="message"){
                                     return <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                     
                                     <div style={{direction:"rtl"}} className="message-data ">
                                       
                                       <span  className="message-data-time">{msg.created_at} </span>
                                        </div>
                                          <FaReply onClick={()=>{
                                          replyHandle(msg)
                                          }} style={{
                                          color:"white",
                                          fontSize:"20px" ,
                                          verticalAlign:"end",
                                          textAlign:"right",
                                          marginLeft:"5px"}}/>
                                        <div className="message my-message"> {convertTextToLinks(msg.message)} </div>
                                        
                                        {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                     <div style={{ marginTop:"5px", display:"flex", gap:"5px",justifyContent:"end"}}>
                                     
                                     <div onClick={()=>{
                                       scrollToDiv(msg.reply_to)
                                     }} style={{
                                       padding:"10px",
                                       borderRadius:"10px",
                                       background:"#ffffffc4",
                                       color:"black",
                                       fontSize:"11px",
                                       maxWidth:"50%",
                                       textAlign:"right"
                                     }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                     <FaArrowTurnUp style={{fontSize:"30px", color:"white",
                                       transform:"scaleX(-1)"}}/>
                                   </div>
                                     }
                                     
                                    </li>  
                                   }else if(msg.message_type ==="file"){
                                        return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                       <div style={{direction:"rtl"}} className="message-data ">
                                         
                                         <span  className="message-data-time">{msg.created_at} </span>
                                        </div>
                                        <FaReply onClick={()=>{
                                          replyHandle(msg)
                                          }} style={{
                                          color:"white",
                                          fontSize:"20px" ,
                                          verticalAlign:"end",
                                          textAlign:"right",
                                          marginLeft:"5px"}}/>
                                       <div className="message my-message "> 
                                         <a href={msg.message} target='_blank' rel="noreferrer" >
                                            برای دانلود فایل کلیک نمایید</a>
                                       </div>
                                       {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& <div style={{ 
                                       marginTop:"5px", 
                                       display:"flex", 
                                       gap:"5px",
                                       justifyContent:"end"}}>
                                     <div onClick={()=>{
                                       scrollToDiv(msg.reply_to)
                                     }} style={{
                                       padding:"10px",
                                       borderRadius:"10px",
                                       background:"#ffffffc4",
                                       color:"black",
                                       fontSize:"11px",
                                       maxWidth:"50%",
                                       textAlign:"right"
                                     }}>{truncateText(msg.replied_to_message.message,80)}</div>
                                     <FaArrowTurnUp style={{fontSize:"30px", color:"white",
                                       transform:"scaleX(-1)"}}/>
                                   </div>}
                                      </li> 
                                   }else if(msg.message_type ==="image"){
                                       return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                       <div style={{direction:"rtl"}} className="message-data ">
                                         
                                         <span  className="message-data-time">{msg.created_at} </span>
                                        </div>
                                        <FaReply onClick={()=>{
                                          replyHandle(msg)
                                          }} style={{
                                          color:"white",
                                          fontSize:"20px" ,
                                          verticalAlign:"end",
                                          textAlign:"right",
                                          marginLeft:"5px"}}/>
                                       <div className="message my-message"> 
                                         <img onClick={()=>{
                                         window.open(msg.message,"_blank")
                                       }} src={msg.message} 
                                         style={{maxHeight:"200px",maxWidth:"200px"}}
                                         alt='sendedImage'/>
                                       </div>
                                       {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& <div style={{ 
                                       marginTop:"5px", 
                                       display:"flex", 
                                       gap:"5px",
                                       justifyContent:"end"}}>
                                     <div onClick={()=>{
                                       scrollToDiv(msg.reply_to)
                                     }} style={{
                                       padding:"10px",
                                       borderRadius:"10px",
                                       background:"#ffffffc4",
                                       color:"black",
                                       fontSize:"11px",
                                       maxWidth:"50%",
                                       textAlign:"right"
                                     }}>{truncateText(msg.replied_to_message.message,80)}</div>
                                     <FaArrowTurnUp style={{fontSize:"30px", color:"white",
                                       transform:"scaleX(-1)"}}/>
                                   </div>}
                                      </li> 
                                   }
                             }
                           } 
                         )}
                           
                         </ul>
                     </div>
                     {typing!==null&&<div style={{textAlign:"right"}} className='isTyping'>
                              
                              <div style={{width:"100%"}} className='bb'>
                                کاربر در حال نوشتن : 
                              </div>
                              <div style={{paddingTop:"10px"}}>
                                {typing}
                              </div>
                           </div>}
                     <div className="chat-message clearfix">
                         <div style={{direction:"ltr",borderRadius:"5px"}} className="input-group mb-0">
                             {tabBtn()}
                         </div>
                     </div>
                 </div>:
                     <div className='notSelected'>
                       <h6 style={{color:"white"}} className='comingsoon'> یکی از چت ها را انتخاب نمایید 
                         <a onClick={()=>{
                                    socket.emit("getOpenChats")
                                   }} style={{
                                    padding:"10px" 
                                    }} href="javascript:void(0);" className="btn-outline-info">
                                     <TbRefresh style={{fontSize:"30px",verticalAlign:"middle" }} />
                         </a>
                       </h6>
                     </div>}
                     
             </div>
         </div>
     </div>
 </div>
    
    }
   
    <script src="https://code.jquery.com/jquery-1.10.2.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.0/dist/js/bootstrap.bundle.min.js"></script>
    <script type="text/javascript">
    </script>
            <Success name="chats" show={modal} closeModal={()=>{setModal(false)
                setFile([])
            }}>
              {inModal()}
            </Success>
            <NotificationContainer/> 
            {window.innerWidth<800&&<div onClick={()=>{
             setIsOpen(!isOpen)
            }}
          style={{position:"absolute",top:"50%" , background:"white",left:"-13px",
            borderRadius:"0 50% 50% 0",zIndex:"99"
          }}>
             {!isOpen?<IoListCircle style={{fontSize:"40px"}} />:<MdCancel style={{fontSize:"40px"}} />}
            </div>}
        </div>
        
    );
}

export default SupportPanel;
