/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useContext, useEffect } from 'react';
import "./Ticket.css"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserContext from "../UserContext/UserContext"
import Cookies from 'js-cookie';
import 'react-notifications/lib/notifications.css';
import { MdAdsClick } from "react-icons/md";
import axios from 'axios';
import InsideTicket from './InsideTicket';
const Ticket = () => {
    const [departs, setDepart] = useState([])
    const [insideDep, setInside] = useState([])
    const [accounts, setAcc] = useState([])
    const [account, setAccount] = useState("")
    
    useEffect(()=>{
        load(true)
        axios.get(url+"api/v1/panel/departments",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                load(false)
                setDepart(res.data.data)
                setAcc(res.data.accounts)
                console.log(res.data.accounts);
                
                
            }).catch(err => {
                load(false)
                console.log(err.response);
            })
    },[])
    const [activ, setActive] = useState({ a1: "active", a2: "" })
    const { url, load } = useContext(UserContext);
    const [eachData, setEach] = useState([])
    const activer = (x) => {
        if (x === 1) {
            setActive({ a1: "active", a2: "" })
        } else if (x === 2) {
            setClass("")
            load(true)
            axios.get(url+"api/v1/panel/tickets",
                {
                    headers: {
                        "Authorization": `bearer ${Cookies.get('token')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    load(false)
                    setEach(res.data)
                }).catch(err => {
                    load(false)
                    console.log(err);
                })
            setActive({ a1: "", a2: "active" })
        }
    }
    const afterRep = (d) => {
        axios.get(url+"api/v1/panel/tickets/" +d,
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                load(false)
                setOwn(res.data)
                
            }).catch(err => {
                load(false)
                console.log(err);
            })
    }
    const [title, setTitle] = useState("")
    const [classs, setClass] = useState("")
    const [department, setDep] = useState("")
    const [priority, setPri] = useState("")
    const [description, setDes] = useState("")
    const [fileUrl, setFileUrl] = useState("")
    const [obs, setObs] = useState("")
    const [file, setFile] = useState([])
    const [ownTicket, setOwn] = useState({ replies: [] })
    const setAllInputNull = () => {
        setTitle("")
        setDep("")
        setPri("")
        setFile([])
        setDes("")
        setFileUrl("")
        setAccount("")
    }  
    const eachTicket = () => {
        return <>
            {eachData.map(d => {
                const status = (s) => {
                    if (s === 0 || s===3) {
                        return <div className='orange'> منتظر پاسخ</div>
                    } else if (s === 1) {
                        return <div className='green'>  پاسخ داده شده </div>
                    } else if(s === 2)  {
                        return <div className='red'> بسته شده </div>
                    }
                }
                return <>
                    <tr>
                        <td>{status(d.status)} </td>
                        <td>{d.title}</td>
                        <td>{d.departments.title}</td>
                        <td>{d.created_at} </td>
                        <th className='lastTh'> <MdAdsClick onClick={() => {
                            load(true)
                            if (classs === "") {
                                axios.get(url+"api/v1/panel/tickets/" + d.id,
                                    {
                                        headers: {
                                            "Authorization": `bearer ${Cookies.get('token')}`,
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }).then(res => {
                                        load(false)
                                        setOwn(res.data)
                                        setClass(d.id)
                                    }).catch(err => {
                                        load(false)
                                        console.log(err);
                                    })
                            } else {
                                setClass("")
                                load(false)
                            }
                        }} /> 
                        </th>
                    </tr>
                    <tr>
                        {classs === d.id && classs !== "" ? (<td className='tickCon' colSpan={8}>
                            <div >
                                <InsideTicket rep={ownTicket} afterRep={afterRep} type="user" name="" close="/close" />
                            </div>
                        </td>) : null}
                    </tr>
                </>
            })}
        </>
    }
    const departShow=()=>{
        return <>
        <label className=" small" > دپارتمان مورد نظر را انتخاب نمایید : </label>
                        <select className='select_input' value={department} name="department" onChange={(e) => {
                             setDep(e.target.value) 
                             departs.map(dd=>{
                                if(dd.id == e.target.value){
                                    setInside(dd.sections)
                                }else if(e.target.value === ""){
                                    setInside([])
                                }
                             })
                             }}>
                            <option value=""> انتخاب کنید </option>
                            {departs.map(d=>{
                              return  <option key={d.id} value={d.id}> {d.title} </option>
                            })}
                            
                        </select>
        </>
    }
    const sectionShow = () =>{
        
        return <>
                        <label className=" small"> موضوع تیکت را انتخاب نمایید : </label>
                        <select className='select_input' name="obstract" value={obs} onChange={(e) => { setObs(e.target.value) }}>
                            {insideDep.length !== 0 ? <option value=""> انتخاب کنید </option> : 
                            <option value=""> ابتدا دپارتمان را انتخاب نمایید </option>}
                            {insideDep.map(ddd=>{
                            return  <option key={ddd.id} value={ddd.id}> {ddd.title} </option>
                            
                            })}
                        </select>
        </>
    }
    const withOne = () => {
        if (activ.a1) {
            return <div className='ticket'>
                <NotificationContainer />

                <div className='row gx-3 mb-3 '>
                <div className='col-md-6 '>
                        {departShow()}
                    </div>
                    <div className='col-md-6 '>
                        {sectionShow()}
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small" > عنوان تیکت خود را وارد نمایید : </label>
                        <input maxLength={50} type='text' className='form-control br0' value={title} name="title" onChange={(e) => { setTitle(e.target.value) }}/>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small"> اولویت تیکت را انتخاب نمایید : </label>
                        <select className='select_input' name="priority" value={priority} onChange={(e) => { setPri(e.target.value) }}>
                            <option value=""> انتخاب کنید </option>
                            <option style={{color:"red" }} value={2}> بالا</option>
                            <option style={{color:"orange" }} value={1}> متوسط </option>
                            <option style={{color:"green" }} value={0}> کم </option>
                        </select>
                    </div>
                    {department == 2?<div className='col-md-12 mt'>
                        <label className=" small"> حساب معاملاتی  : </label>
                        <select className='select_input' name="priority" value={account}
                        onChange={(e) => { setAccount(e.target.value) }}>
                            <option value=""> انتخاب کنید </option>
                            {console.log('account', accounts)}
                            {accounts.map((d,i)=>{
                            
                                return <option value={d.id} key={i}> {d.login !== null?
                                    (d.order.product
                                        ? d.order.product.type + " ( " + d.login + " ) " + " / " + d.order.product.title
                                        : d.order.category.title + " ( " + d.login + " ) " + " / " + d.order.product_id
                                      ) 
                                    :
                                    (
                                        d.order.product
                                          ? d.order.product.type + " / " + d.order.product.title + " / غیر فعال"
                                          : d.order.category.title + " / " + d.order.product_id + " / غیر فعال"
                                      )
                                    } </option>
                            })}
                            <option value="0"> ندارم </option>
                        </select>
                    </div>:null}
                    <div className="fulltextarea">
                        <label className=" small"> متن پیام خود را وارد نمایید : </label>
                        <textarea name='description' value={description} className='form-control br0' onChange={(e) => { setDes(e.target.value) }} />
                    </div>
                    <div className="fulltextarea">
                        <label className=" small"> فایل های ضمیمه ای خود را (در صورت نیاز) بارگذاری نمایید : </label>
                        <input value={fileUrl} type='file' accept='.jpg , .jpeg , .png , .webp , .avif , .gif , .zip' name='file' className='form-control ' onChange={(e) => {
                            setFile(e.target.files[0]);
                            setFileUrl(e.target.value)
                        }} />
                    </div>
                    <div className='fulltextarea'>
                        <button onClick={() => {
                            if (title !== "" && description !== "" &&
                                department !== "" && priority !== "" && obs !== "") {
                                if(department==2){
                                 if(account !== ""){
                                    load(true)
                                let data = {
                                    title: title,
                                    department: department , 
                                    priority: priority ,
                                    description: description ,
                                    file: file , 
                                    department_section: obs ,
                                    account_id: account
                                }
                           
                                axios.post(url+"api/v1/panel/tickets/store", data,
                                    {
                                        headers: {
                                            "Authorization": `bearer ${Cookies.get('token')}`,
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }).then(res => {
                                        load(false)
                                        setAllInputNull()
                                        NotificationManager.success(" در قسمت تیکت های من می توانید وضعیت تیکت خود را مشاهده نمایید", "تیکت شما با موفقیت ارسال شد", 5000)
                                    }).catch(err => {
                                        load(false)
                                        console.log(err);
                                    })
                                 }else{
                                    NotificationManager.warning("لطفا حساب معاملاتی را مربوطه را انتخاب نمایید", "تیکت ارسال نشد", 2000)
                                 }
                                }else{
                                    load(true)
                                let data = {
                                    title: title,
                                    department: department , 
                                    priority: priority ,
                                    description: description ,
                                    file: file , 
                                    department_section: obs
                                }
                           
                                axios.post(url+"api/v1/panel/tickets/store", data,
                                    {
                                        headers: {
                                            "Authorization": `bearer ${Cookies.get('token')}`,
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }).then(res => {
                                        load(false)
                                        setAllInputNull()
                                        
                                        NotificationManager.success(" در قسمت تیکت های من می توانید وضعیت تیکت خود را مشاهده نمایید", "تیکت شما با موفقیت ارسال شد", 5000)
                                    }).catch(err => {
                                        load(false)
                                        console.log(err);
                                    })
                                }

                            } else {
                                NotificationManager.warning("لطفا تمامی فیلد ها را پر نمایید", "تیکت ارسال نشد", 2000)
                            }
                        }} className='btnreply1 mt'>
                            ارسال تیکت
                        </button>
                    </div>
                </div>
            </div>
        } else if (activ.a2) {
            return <div className='ticket1'>
                <div className='eachTicket'>
                    <p className='bb'> تیکت ها و وضعیت تیکت ها : </p>
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover  ">
                            <thead >
                                <tr>
                                    <th>وضعیت </th>
                                    <th>عنوان</th>
                                    <th>دپارتمان</th>
                                    <th>ایجاد تیکت</th>
                                    <th>  </th>

                                </tr>
                            </thead>
                            <tbody>
                                {eachData.length === 0? <tr>
                                    <th style={{textAlign:"center"}} colSpan={8}> تاریخچه تیکت ها خالی می باشد  </th>
                                </tr>: null}
                                {eachTicket()}
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        }
    }
    return (
        <>
            <div className="app-header-navigation">
                <div className="tabs">
                    <a onClick={() => { activer(1) }} className={activ.a1} >
                        افزودن تیکت
                    </a>
                    <a onClick={() => { activer(2) }} className={activ.a2}  >
                        تیکت های من
                    </a>
                </div>
            </div>
            {withOne()}
        </>

    );
};

export default Ticket;