/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState, useContext } from "react";
import { MdSupportAgent } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import "./SupportDashboard.css";
import Cookies from "js-cookie";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import { GiCancel } from "react-icons/gi";
import { MdAdsClick } from "react-icons/md";
import UserContext from "../UserContext/UserContext";
import axios from "axios";
import InsideTicket from "../Dashbord/InsideTicket";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { FaCircle } from "react-icons/fa";
import Success from "../Success/Success";
import { CiCircleChevDown } from "react-icons/ci";
import { TiTickOutline } from "react-icons/ti";
import { TiTick } from "react-icons/ti";
import { BsTicketPerforatedFill } from "react-icons/bs";
export default function SupportTicket() {
  const [eachData, setEach] = useState({ data: [], links: [], user: [] });
  const [classs, setClass] = useState("");
  const [classs1, setClass1] = useState([false]);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [id, setId] = useState([]);
  const [userData, setUserData] = useState({
    accounts: [],
    tickets: [],
    confirmed_authentications: [],
    all_referrals: [],
  });
  const [modal, setModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  function formatToK(number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(number % 1000 === 0 ? 0 : 1) + "k";
    }
    return number.toString();
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const sendSearchRequest = (term) => {
    if (term.length > 2) {
      load(true);
      let searchUrl = () => {
        if (activ.a1) {
          return "show_open_tickets?query=" + term;
        } else if (activ.a2) {
          return "show_my_tickets?type=unanswered&&query=" + term;
        } else if (activ.a3) {
          return "show_my_tickets?type=answered&&query=" + term;
        } else {
          return "show_my_tickets?type=closed&&query=" + term;
        }
      };
      axios
        .get(url + "api/v1/panel/search/" + searchUrl(), {
          headers: { Authorization: `bearer ${Cookies.get("token")}` },
        })
        .then((res) => {
          load(false);
          console.log(res.data);
          setEach(res.data.tickets);
        })
        .catch((err) => {
          load(false);
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getData("show_open_tickets");
  }, []);
  const { user, load, url } = useContext(UserContext);
  const [ownTicket, setOwn] = useState({ replies: [], account: {} });
  const [activ, setActive] = useState({
    a1: "active",
    a2: "",
    a3: "",
    a4: "",
    a5: "",
  });
  const [dis, setDis] = useState({ a1: "", a2: "", a3: "", a4: "" });
  const afterRep = (d) => {
    axios
      .get(url + "api/v1/panel/show_ticket_details/" + d, {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        load(false);
        setOwn(res.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  };
  const eachTicket = () => {
    return (
      <>
        {eachData.data.map((d) => {
          const status = (s) => {
            if (s === 0) {
              return <div className="orange"> منتظر پاسخ</div>;
            } else if (s === 1) {
              return <div className="green"> پاسخ داده شده </div>;
            } else if (s === 2) {
              return <div className="red"> بسته شده </div>;
            } else if (s === 3) {
              return <div className="purple"> تعلیق شده </div>;
            }
          };
          const green = (i) => {
            let idUniq = id.filter(
              (value, index, array) => array.indexOf(value) === index
            );
            return (
              <>
                {idUniq.map((ddd) => {
                  if (i === ddd) {
                    return (
                      <FaCircle key={ddd} className="animation" color="green" />
                    );
                  }
                })}
              </>
            );
          };
          return (
            <>
              <tr key={d.id}>
                <td className="img">
                  <img
                    onClick={() => {
                      setUserData(d.user);
                      setModal(true);
                    }}
                    style={{
                      width: "36px",
                      height: "36px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      margin: "auto",
                      display: "flex",
                    }}
                    src={url + "storage" + d.user.image}
                    alt="userImage"
                  />
                </td>
                <td>
                  {" "}
                  {d.user.first_name !== null
                    ? d.user.first_name + " " + d.user.last_name
                    : d.user.username}{" "}
                </td>
                <td> {status(d.status)} </td>
                <td> {d.title} </td>
                <td>
                  {d.departments !== null ? d.departments.title : "ندارد"}
                </td>
                <td>
                  <div className="dfjc">
                    {" "}
                    {d.created_at} {green(d.id)}{" "}
                  </div>
                </td>
                <td>
                  <div className="dfjc">
                    {" "}
                    {d.updated_at} {green(d.id)}{" "}
                  </div>
                </td>
                {activ.a4 === "active" ? (
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.rating !== undefined && d.rating !== null
                      ? " 5 / " + d.rating.rating
                      : "ندارد"}{" "}
                  </td>
                ) : null}

                <th className="lastTh">
                  {" "}
                  <MdAdsClick
                    onClick={() => {
                      load(true);
                      setId((oldValues) => {
                        return oldValues.filter((e) => e !== d.id);
                      });

                      if (classs === "") {
                        if (activ.a1 && user.dataes.roles[0].name !== "admin") {
                          axios
                            .get(
                              url + "api/v1/panel/tickets/" + d.id + "/pick",
                              {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              load(false);
                              NotificationManager.success(
                                " این تیکت به شما اختصاص یافت ",
                                "انتخاب شد",
                                3000
                              );
                              load(true);
                              axios
                                .get(
                                  url +
                                    "api/v1/panel/show_ticket_details/" +
                                    d.id,
                                  {
                                    headers: {
                                      Authorization: `bearer ${Cookies.get(
                                        "token"
                                      )}`,
                                      "Content-Type": "multipart/form-data",
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(res.data);

                                  load(false);
                                  setOwn(res.data);
                                  setClass(d.id);
                                })
                                .catch((err) => {
                                  load(false);
                                  console.log(err);
                                });
                            })
                            .catch((err) => {
                              if (err.response.status === 401) {
                                NotificationManager.warning(
                                  "این تیکت قبلا توسط پشتیبان دیگری انتخاب شده",
                                  "انتخاب نشد",
                                  4000
                                );
                              }
                              load(false);
                              console.log(err);
                            });
                        } else {
                          axios
                            .get(
                              url + "api/v1/panel/show_ticket_details/" + d.id,
                              {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            )
                            .then((res) => {
                              load(false);
                              setOwn(res.data);
                              setClass(d.id);
                            })
                            .catch((err) => {
                              load(false);
                              console.log(err);
                            });
                        }
                      } else {
                        setClass("");
                        load(false);
                      }
                    }}
                  />{" "}
                </th>
              </tr>
              <tr>
                {classs === d.id && classs !== "" ? (
                  <td className="tickCon" colSpan={8}>
                    <div>
                      <InsideTicket
                        rep={ownTicket}
                        afterRep={afterRep}
                        type="admin"
                        name={ownTicket.user}
                        close="/admin_close"
                      />
                    </div>
                  </td>
                ) : null}
              </tr>
            </>
          );
        })}
      </>
    );
  };
  const getData = (w) => {
    load(true);
    axios
      .get(url + "api/v1/panel/" + w, {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        load(false);
        setEach(res.data.tickets);
        console.log(res.data);
        if (w === "show_open_tickets") {
          setCount(res.data.tickets.length);
          setCount1(res.data.count);
        } else if (w === "show_my_tickets?type=unanswered") {
          setCount1(res.data.tickets.length);
        }
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  };
  const activer = (x) => {
    if (x === 1) {
      getData("show_open_tickets");
      setActive({ a1: "active", a2: "", a3: "", a4: "", a5: "" });
    } else if (x === 2) {
      getData("show_my_tickets?type=unanswered");
      setActive({ a1: "", a2: "active", a3: "", a4: "", a5: "" });
    } else if (x === 3) {
      getData("show_my_tickets?type=answered");

      setActive({ a1: "", a2: "", a3: "active", a4: "", a5: "" });
    } else if (x === 4) {
      getData("show_my_tickets?type=closed");

      setActive({ a1: "", a2: "", a3: "", a4: "active", a5: "" });
    } else if (x === 5) {
      getData("show_my_tickets?type=suspended");

      setActive({ a1: "", a2: "", a3: "", a4: "", a5: "active" });
    }
  };
  const newCount = (count) => {
    if (count > 0) {
      return <div className="newCount"> {count} </div>;
    }
  };
  const stat = (s) => {
    if (s === 1) {
      return "فعال";
    } else {
      return "غیر فعال";
    }
  };
  const authType = (a) => {
    if (a === "Phone_auth") {
      return "احراز هویت شماره همراه انجام شده";
    } else if (a === "Email_auth") {
      return "احراز هویت ایمیل انجام شده";
    } else if (a === "Identity_auth") {
      return "احراز هویت اطلاعات هویتی انجام شده";
    } else if (a === "IdCard_auth") {
      return "احراز هویت تصویر کارت ملی انجام شده";
    } else if (a === "SelfImage_auth") {
      return "احراز هویت تصویر سلفی با کارت ملی انجام شده";
    } else if (a === "Video_auth") {
      return "احراز هویت ویدیویی انجام شده";
    }
  };

  const fileShow = (rep) => {
    if (rep.file !== null) {
      if (/\.(jpg|jpeg|png|webp|avif|gif)$/.test(url + "storage/" + rep.file)) {
        return (
          <img
            onClick={() => {
              window.open(url + "storage/" + rep.file, "_blank");
            }}
            className="tickImage"
            src={url + "storage/" + rep.file}
            alt="ticketFile"
          />
        );
      } else {
        return (
          <a
            style={{ color: "blue" }}
            href={url + "storage/" + rep.file}
            target="_blank"
            rel="noreferrer"
          >
            برای دانلود فایل آپلود شده کلیک نمایید
          </a>
        );
      }
    } else {
      return null;
    }
  };
  const onSearch = () => {
    if (searchTerm.length > 2) {
      return "&&query=" + searchTerm;
    } else {
      return "";
    }
  };
  const liShows = () => {
    return (
      <>
        {eachData.links.map((dd) => {
          let c = () => {
            if (dd.active) {
              return "ac";
            } else {
              return null;
            }
          };
          if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
            return (
              <li
                key={dd.label}
                className={"page__numbers" + " " + c()}
                onClick={() => {
                  if (dd.url !== null) {
                    load(true);
                    console.log(dd.url + tab() + onSearch());

                    axios
                      .get(dd.url + tab() + onSearch(), {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);
                        setEach(res.data.tickets);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
              >
                {" "}
                {dd.label}{" "}
              </li>
            );
          }
        })}
      </>
    );
  };
  let tab = () => {
    if (activ.a1) {
      return "&&show_open_tickets";
    } else if (activ.a2) {
      return "&&type=unanswered";
    } else if (activ.a3) {
      return "&&type=answered";
    } else {
      return "&&type=closed";
    }
  };
  return (
    <>
      <NotificationContainer />
      <Success
        show={modal}
        name="sadweeq"
        closeModal={() => {
          setModal(false);
        }}
      >
        <div
          className="userDetail"
          style={{ minWidth: "640px", padding: "10px" }}
        >
          <div className="bB p5"> اطلاعات کاربر {userData.username} : </div>
          <div className="orderDetail bb">
            <div className="fl" style={{ padding: "5px" }}>
              {" "}
              نام و نام خانوادگی{" "}
            </div>
            <div style={{ padding: "5px" }}>
              {userData.first_name + " " + userData.last_name}
            </div>
          </div>
          <div style={{ textAlign: "right", padding: "10px" }} className=" bb">
            <div className="fl">
              {" "}
              حساب ها :{" "}
              <CiCircleChevDown
                style={{
                  fontSize: "25px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (dis.a1 === "") {
                    setDis({ a1: "none", a2: dis.a2, a3: dis.a3, a4: dis.a4 });
                  } else {
                    setDis({ a1: "", a2: dis.a2, a3: dis.a3, a4: dis.a4 });
                  }
                }}
              />{" "}
            </div>
            <table
              style={{ display: dis.a1, marginTop: "10px" }}
              className="table table-hover  "
              border={1}
            >
              <thead>
                <tr>
                  <th> نوع حساب</th>
                  <th> وضعیت </th>
                  <th> توسط </th>
                  <th> ورود</th>
                  <th> مرحله </th>
                </tr>
              </thead>
              <tbody>
                {userData.accounts.map((d) => {
                  return (
                    <tr key={d.id}>
                      <td>
                        {d.order.product
                          ? `${d.order.product.type} | ${d.order.product.title} | ${d.order.broker.name}`
                          : `${d.order.category.title} | ${formatToK(
                              d.order.product_id
                            )} | ${d.order.broker.name}`}
                      </td>

                      <td> {stat(d.status)} </td>
                      <td>
                        {" "}
                        {d.created_with === "user" ? "کاربر" : "فنفیکس"}{" "}
                      </td>
                      <td style={{ direction: "ltr", textAlign: "left" }}>
                        {" "}
                        {d.login}{" "}
                      </td>

                      <td> {d.type} </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ textAlign: "right", padding: "10px" }} className=" bb">
            <div className="fl">
              {" "}
              تیکت های کاربر :{" "}
              <CiCircleChevDown
                style={{
                  fontSize: "25px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (dis.a2 === "") {
                    setDis({ a1: dis.a1, a2: "none", a3: dis.a3, a4: dis.a4 });
                  } else {
                    setDis({ a1: dis.a1, a2: "", a3: dis.a3, a4: dis.a4 });
                  }
                }}
              />{" "}
            </div>
            <div>
              {" "}
              <table
                style={{ display: dis.a2, marginTop: "10px" }}
                className="table table-hover  "
                border={1}
              >
                <thead>
                  <tr>
                    <th> عنوان تیکت </th>
                    <th> دپارتمان </th>
                    <th> پاسخ توسط </th>
                    <th> وضعیت </th>
                    <th> تاریخ </th>
                  </tr>
                </thead>
                <tbody>
                  {userData.tickets.map((d) => {
                    return (
                      <>
                        <tr key={d.id}>
                          <td> {d.title}</td>
                          <td>
                            {" "}
                            {d.department === 1
                              ? "پشتیبانی عمومی"
                              : "پشتیبانی فنی"}{" "}
                          </td>
                          <td>
                            {" "}
                            {d.responder !== null
                              ? d.responder.first_name +
                                " " +
                                d.responder.last_name
                              : "ندارد"}{" "}
                          </td>
                          <td> {d.status === 2 ? "بسته" : "باز"} </td>
                          <td> {d.created_at} </td>
                          <td>
                            <MdAdsClick
                              style={{
                                verticalAlign: "middle",
                                fontSize: "23px",
                              }}
                              onClick={() => {
                                if (classs1[0]) {
                                  setClass1([false]);
                                  console.log(d);
                                } else {
                                  setClass1([true, d]);
                                  console.log(d);
                                }
                              }}
                            />{" "}
                          </td>
                        </tr>
                        {classs1[0] && classs1[1].id === d.id ? (
                          <tr>
                            <th style={{ background: "#00000096" }} colSpan={8}>
                              <li
                                style={{ marginBottom: "10px" }}
                                className="tmln__item tmln__item--bg-dark"
                              >
                                <span>
                                  {" "}
                                  <BsTicketPerforatedFill /> تیکت {
                                    d.created_at
                                  }{" "}
                                  توسط {userData.username} ایجاد شده{" "}
                                </span>
                                <span>
                                  {" "}
                                  برای دپارتمان{" "}
                                  {d.department === 2
                                    ? " فنی "
                                    : " پشتیبانی "}{" "}
                                </span>
                                <p className="tmln__item-headline">
                                  {" "}
                                  <TiTick /> عنوان تیکت -- {d.title}{" "}
                                </p>
                                <div className="tikcontent">
                                  <p> {d.description} </p>
                                  {fileShow(d)}
                                </div>
                              </li>
                              {d.replies.map((dd) => {
                                if (dd.reply_type === "admin") {
                                  return (
                                    <li className="tmln__item tmln__item--bg-lght">
                                      <span>
                                        {" "}
                                        <MdSupportAgent /> پاسخ تیکت{" "}
                                        {dd.created_at} توسط دپارتمان{" "}
                                        {d.department === 2
                                          ? " فنی "
                                          : " پشتیبانی "}{" "}
                                        :{" "}
                                      </span>
                                      <p
                                        className="answer"
                                        style={{
                                          background: "white",
                                          color: "black",
                                        }}
                                      >
                                        {" "}
                                        {dd.description}{" "}
                                      </p>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li className="tmln__item tmln__item--bg-dark">
                                      <span>
                                        {" "}
                                        <FaUser /> پاسخ کاربر{" "}
                                        {" " + userData.username + " در"}{" "}
                                        {dd.created_at} :{" "}
                                      </span>

                                      <p className="tikcontent">
                                        {dd.description}
                                      </p>
                                    </li>
                                  );
                                }
                              })}
                            </th>
                          </tr>
                        ) : null}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ textAlign: "right", padding: "10px" }} className=" bb">
            <div className="fl">
              {" "}
              احراز هویت های کاربر :{" "}
              <CiCircleChevDown
                style={{
                  fontSize: "25px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (dis.a3 === "") {
                    setDis({ a1: dis.a1, a2: dis.a2, a3: "none", a4: dis.a4 });
                  } else {
                    setDis({ a1: dis.a1, a2: dis.a2, a3: "", a4: dis.a4 });
                  }
                }}
              />{" "}
            </div>
            <div>
              {" "}
              <table
                style={{ display: dis.a3, marginTop: "10px" }}
                className="table table-hover  "
                border={1}
              >
                <thead>
                  <tr>
                    <th> احراز هویت تکمیل شده </th>
                    <th> تاریخ انجام </th>
                  </tr>
                </thead>
                <tbody>
                  {userData.confirmed_authentications.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td> {authType(d.type)}</td>
                        <td> {d.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ textAlign: "right", padding: "10px" }} className=" bb">
            <div className="fl"> افراد دعوت شده توسط کاربر : </div>
            {userData.all_referrals.map((d) => {
              return (
                <div className="f12" style={{ margin: "10px" }} key={d.id}>
                  {d.first_buy ? (
                    <TiTickOutline
                      style={{
                        verticalAlign: "middle",
                        fontSize: "25px",
                        color: "green",
                      }}
                    />
                  ) : (
                    <GiCancel
                      style={{
                        verticalAlign: "middle",
                        fontSize: "20px",
                        color: "red",
                      }}
                    />
                  )}{" "}
                  {d.username}{" "}
                </div>
              );
            })}
          </div>
        </div>
      </Success>
      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              activer(1);
            }}
            className={activ.a1 + " " + "bl"}
          >
            تیکت ها
            {newCount(count)}
          </a>
          <a
            onClick={() => {
              activer(2);
            }}
            className={activ.a2 + " " + "bl"}
          >
            بی پاسخ
            {newCount(count1)}
          </a>
          <a
            onClick={() => {
              activer(3);
            }}
            className={activ.a3 + " " + "bl"}
          >
            پاسخ داده
          </a>
          <a
            onClick={() => {
              activer(4);
            }}
            className={activ.a4 + " " + "bl"}
          >
            بسته شده
          </a>
          <a
            onClick={() => {
              activer(5);
            }}
            className={activ.a5 + " " + "bl"}
          >
            تعلیق شده
          </a>
        </div>
      </div>
      <div style={{ width: "90%" }} className="ticket1">
        <div className="eachTicket">
          <p className="bb"> تیکت ها و وضعیت تیکت ها : </p>
          <p className="f12">
            {" "}
            برای مشاهده اطلاعات کاربر روی تصویر آن کلیک نمایید{" "}
          </p>
          <div className="table-responsive-md">
            <input
              style={{ width: "35%", margin: "10px" }}
              className="searchIn"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              type="text"
              placeholder="جستجو نام کاربری ..."
            />
            <table border={1} className="table table-hover  ">
              <thead>
                <tr>
                  <th> </th>
                  <th> اطلاعات کاربر</th>
                  <th>وضعیت </th>
                  <th>عنوان</th>
                  <th>دپارتمان</th>
                  <th> زمان ایجاد تیکت</th>
                  <th> بروزرسانی </th>
                  {activ.a4 === "active" ? <th> امتیاز</th> : null}
                  <th> </th>
                </tr>
              </thead>
              <tbody>{eachTicket()}</tbody>
            </table>
            <ul class="page">
              <li
                onClick={() => {
                  if (eachData.prev_page_url !== null) {
                    console.log(eachData.prev_page_url + tab() + onSearch());

                    load(true);
                    axios
                      .get(eachData.prev_page_url + tab() + onSearch(), {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);

                        setEach(res.data.tickets);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
                className="page__btn"
              >
                <span class="material-icons">
                  <IoChevronBackCircle />
                </span>
              </li>
              {liShows()}
              <li
                onClick={() => {
                  if (eachData.next_page_url !== null) {
                    load(true);
                    console.log(eachData.prev_page_url + tab() + onSearch());
                    axios
                      .get(eachData.next_page_url + tab() + onSearch(), {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);
                        setEach(res.data.tickets);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
                className="page__btn"
              >
                <span class="material-icons">
                  <IoArrowForwardCircleSharp />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
