/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import "./AdminUsers.css";
import { MdAdsClick } from "react-icons/md";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Success from "../Success/Success";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import Analyze from "../Analyze/Analyze";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function AdminReport() {
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState({ data: [], links: [] });
  const [acc, setAcc] = useState("");
  const [tr, setTr] = useState("");
  const [type, setType] = useState(1);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [analyzeType, setAnalayzeType] = useState(1);
  const [result, setResult] = useState("");
  const [total, setTotal] = useState([""]);

  const chartData = () => {
    if (total[0] === "account") {
      return {
        labels: ["آمار فروش بازه زمانی انتخاب شده"],
        datasets: [
          {
            label: "کل مبلغ فروش",
            data: [total[1]],
            backgroundColor: "yellowgreen",
          },
          {
            label: "مبلغ خالص دریافتی",
            data: [+total[2]],
            backgroundColor: "blue",
          },
        ],
      };
    } else if (total[0] === "transaction") {
      return {
        labels: ["آمار واریزی های بازه زمانی انتخاب شده"],
        datasets: [
          {
            label: "کل",
            data: [+total[1]],
            backgroundColor: "#14ff00ab",
          },
          {
            label: "coinsbuy",
            data: [+total[2]],
            backgroundColor: "#0004ffab",
          },
          {
            label: "tc-pay",
            data: [+total[3]],
            backgroundColor: "#ffffffab",
          },
          {
            label: "Wallet",
            data: [+total[4]],
            backgroundColor: "yellow",
          },
        ],
      };
    }
  };
  const option = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "white",
          font: {
            family: "iransans",
            size: 14,
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white", // Set color for X-axis labels
          font: {
            family: "iransans", // Optional: set font family
          },
        },
        grid: {
          color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
        },
      },
      y: {
        ticks: {
          color: "white", // Set color for Y-axis labels
          font: {
            family: "iransans", // Optional: set font family
          },
        },
        grid: {
          color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
        },
        beginAtZero: true,
      },
    },
  };
  const translator = (text) => {
    if (text === "paid") {
      return "با پرداخت";
    } else if (text === "non-paid") {
      return "بدون پرداخت";
    } else if (text === "" || text === "all") {
      return "همه";
    } else if (text === "tcpay") {
      return "TC-Pay";
    } else if (text === "wallet") {
      return "کیف پول";
    } else if (text === "coinsbuy") {
      return "Coins-Buy";
    }
  };
  const inModalShow = () => {
    return <></>;
  };
  const { load, url } = useContext(UserContext);

  useEffect(() => {
    load(true);
    axios
      .get(url + "api/v1/admin/reports/accounts", {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        console.log(res.data.data);
        setUsers(res.data.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);
  const usersShow = () => {
    return (
      <>
        {users.data?.map((d) => {
          return (
            <tr
              style={{
                textAlign: "center",
              }}
              key={d.id}
            >
              <th>{d.user?.username}</th>
              <th> {d.order?.product?.title} </th>
              <th> {d.type} </th>
              <th>
                {" "}
                {type == 1
                  ? d.transaction?.discount_price
                  : d.discount_price}{" "}
              </th>
              <th>
                {" "}
                {type == 1
                  ? d.transaction?.received_amount
                  : d.received_amount}{" "}
              </th>
              <th>
                {" "}
                {type == 1 ? d.transaction?.traceNumber : d.traceNumber}{" "}
              </th>
              <th> {d.created_at} </th>
              <th> {d.updated_at} </th>
              <th className="lastTh">
                <MdAdsClick onClick={() => {}} />{" "}
              </th>
            </tr>
          );
        })}
      </>
    );
  };
  const liShows = () => {
    return (
      <>
        {users.links?.map((dd) => {
          let c = () => {
            if (dd.active) {
              return "ac";
            } else {
              return null;
            }
          };
          if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
            return (
              <li
                key={dd.label}
                className={"page__numbers " + c()}
                onClick={() => {
                  if (dd.url !== null) {
                    load(true);
                    axios
                      .get(dd.url + "&" + result[3], {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);

                        setUsers(res.data.data);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
              >
                {" "}
                {dd.label}{" "}
              </li>
            );
          }
        })}
      </>
    );
  };
  const [activer, setActiver] = useState({ a1: "active", a2: "", a3: "" });

  const mainShow = () => {
    return (
      <>
        <div className="transAction">
          <div
            style={{
              margin: "0",
            }}
            className="row f12 bb"
          >
            <div className="col-md-6">
              <div
                style={{
                  color: "white",
                  direction: "rtl",
                  textAlign: "right",
                  display: "grid",
                  gap: "10px",
                }}
              >
                <div className="dfjc">
                  <label>از تاریخ : </label>
                  <input
                    className="searchIn"
                    style={{ marginRight: "10px" }}
                    type="date"
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    required
                  />
                </div>
                <div className="dfjc">
                  <label>تا تاریخ :</label>
                  <input
                    className="searchIn"
                    style={{ marginRight: "10px" }}
                    type="date"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    required
                  />
                </div>
                <div className="dfjc">
                  <label>برحسب :</label>
                  <select
                    className="searchIn"
                    style={{ marginRight: "10px" }}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option
                      style={{
                        color: "black",
                      }}
                      value={1}
                    >
                      {" "}
                      حساب
                    </option>
                    <option
                      style={{
                        color: "black",
                      }}
                      value={2}
                    >
                      {" "}
                      تراکنش
                    </option>
                  </select>
                </div>
                {type == 1 && activer.a1 ? (
                  <div className="dfjc">
                    <label>نوع حساب :</label>
                    <select
                      className="searchIn"
                      style={{ marginRight: "10px" }}
                      value={acc}
                      onChange={(e) => setAcc(e.target.value)}
                    >
                      <option
                        style={{
                          color: "black",
                        }}
                        value="all"
                      >
                        همه
                      </option>
                      <option
                        style={{
                          color: "black",
                        }}
                        value="paid"
                      >
                        {" "}
                        با پرداخت
                      </option>
                      <option
                        style={{
                          color: "black",
                        }}
                        value="non-paid"
                      >
                        {" "}
                        بدون پرداخت
                      </option>
                    </select>
                  </div>
                ) : null}
                {type == 2 && activer.a1 ? (
                  <div className="dfjc">
                    <label>نوع تراکنش :</label>
                    <select
                      className="searchIn"
                      style={{ marginRight: "10px" }}
                      value={tr}
                      onChange={(e) => setTr(e.target.value)}
                    >
                      <option value="all">همه</option>
                      <option
                        style={{
                          color: "black",
                        }}
                        value="coinsbuy"
                      >
                        {" "}
                        Coins-Buy
                      </option>
                      <option
                        style={{
                          color: "black",
                        }}
                        value="tcpay"
                      >
                        {" "}
                        TC-Pay
                      </option>
                      <option
                        style={{
                          color: "black",
                        }}
                        value="wallet"
                      >
                        {" "}
                        کیف پول
                      </option>
                    </select>
                  </div>
                ) : null}

                <div>
                  <button
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      outline: "0",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      if (start !== "" && end !== "") {
                        if (type == 1) {
                          load(true);
                          let query = `start_date=${start}&end_date=${end}&type=${acc}`;
                          let accOrTr = activer.a1 ? "reports" : "analyze";
                          axios
                            .get(
                              url +
                                "api/v1/admin/" +
                                accOrTr +
                                "/accounts?" +
                                query,
                              {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              }
                            )
                            .then((res) => {
                              load(false);
                              console.log(res.data);
                              setUsers(res.data.data);
                              if (activer.a1) {
                                setResult([
                                  "از " + start + " تا " + end,
                                  acc,
                                  "حساب",
                                  query,
                                  res.data.data.total
                                    ? res.data.data.total
                                    : null,
                                  query,
                                ]);

                              } else {
                                setTotal([
                                  "account",
                                  res.data.total_discount_price,
                                  res.data.total_received_amount,
                                ]);
                                setAnalayzeType(1);
                              }
                            })
                            .catch((err) => {
                              load(false);
                              console.log(err);
                            });
                        } else if (type == 2) {
                          load(true);
                          let query = `start_date=${start}&end_date=${end}&type=${tr}`;
                          let accOrTr = activer.a1 ? "reports" : "analyze";
                          axios
                            .get(
                              url +
                                "api/v1/admin/" +
                                accOrTr +
                                "/transactions?" +
                                query,
                              {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              }
                            )
                            .then((res) => {
                              load(false);
                              console.log("res..data", res.data);
                              setUsers(res.data.data);
                              if (activer.a1) {
                                setResult([
                                  "از " + start + " تا " + end,
                                  tr,
                                  "تراکنش",
                                  query,
                                  res.data.data.total
                                    ? res.data.data.total
                                    : null,
                                  query,
                                ]);
                              } else {
                                let walletTotal = 0;

                                res.data.data.forEach((element) => {
                                  if (
                                    +element.gateway === 1 &&
                                    +element.verified_by > 1
                                  ) {
                                    // console.log("element",element)
                                    walletTotal += +element.amount;
                                  }
                                });

                                setTotal([
                                  "transaction",
                                  +res.data.total,
                                  res.data.coinsbuy,
                                  res.data.tcpay,
                                  walletTotal,
                                ]);
                                setAnalayzeType(2);
                              }
                            })
                            .catch((err) => {
                              load(false);
                              console.log(err);
                            });
                        }
                      } else {
                        NotificationManager.warning(
                          "لطفا بازه زمانی را وارد نمایید"
                        );
                      }
                    }}
                  >
                    {" "}
                    {activer.a1 ? "دریافت گزارش" : "مشاهده آنالیز"}
                  </button>
                </div>
              </div>
            </div>
            {result !== "" && activer.a1 && (
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="col-md-6"
              >
                <div
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#0006",
                    border: "1px solid white",
                    marginBottom: "10px",
                  }}
                  className="dfjc"
                >
                  <div> تعداد نتایج یافت شده : </div>
                  <div> {result[4]}</div>
                </div>
                <div
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#0006",
                    border: "1px solid white",
                    marginBottom: "10px",
                  }}
                  className="dfjc"
                >
                  <div> بازه زمانی : </div>
                  <div> {result[0]}</div>
                </div>
                <div
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#0006",
                    border: "1px solid white",
                    marginBottom: "10px",
                  }}
                  className="dfjc"
                >
                  <div> برحسب : </div>
                  <div> {result[2]}</div>
                </div>
                <div
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#0006",
                    border: "1px solid white",
                    marginBottom: "10px",
                  }}
                  className="dfjc"
                >
                  <div> نوع {result[2]} : </div>
                  <div> {translator(result[1])}</div>
                </div>
                <button
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    outline: "0",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() => {
                    load(true);
                    let accOrTr =
                      result[2] === "تراکنش" ? "transactions" : "accounts";
                    const token = Cookies.get("token");
                    axios
                      .get(
                        `${url}api/v1/admin/reports/${accOrTr}/export?${result[5]}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          responseType: "blob",
                        }
                      )
                      .then((res) => {
                        load(false);
                        const blob = new Blob([res.data], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        const link = document.createElement("a");
                        const url = window.URL.createObjectURL(blob);
                        link.href = url;
                        link.download = result[0] + ".xlsx"; // Specify the file name for download
                        document.body.appendChild(link);
                        link.click();

                        // Clean up
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                      })
                      .catch((err) => {
                        load(false);
                        console.error("Error downloading the file:", err);
                      });
                  }}
                >
                  {" "}
                  دریافت فایل اکسل{" "}
                </button>
              </div>
            )}
            {total[0] !== "" && activer.a2 && (
              <>
                <div className="col-md-6">
                  <Bar options={option} data={chartData()} />
                </div>
              </>
            )}
          </div>
          <div
            style={{
              overflowX: "auto",
            }}
            className="eachTicket mt"
          >
            {activer.a1 ? (
              <div className="table-responsive-md">
                <table border={1} className="table table-hover">
                  <thead>
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <th> کاربر </th>
                      <th
                        style={{
                          minWidth: "52px",
                        }}
                      >
                        {" "}
                        محصول{" "}
                      </th>
                      <th> نوع </th>
                      <th> مبلغ درخواستی </th>
                      <th> مبلغ دریافتی </th>
                      <th
                        style={{
                          maxWidth: "200px",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {" "}
                        TX-ID{" "}
                      </th>
                      <th> ایجاد </th>
                      <th> بروزرسانی </th>
                      <th className="lastTh"></th>
                    </tr>
                  </thead>
                  <tbody>{usersShow()}</tbody>
                </table>
                <ul class="page">
                  <li
                    onClick={() => {
                      if (users.prev_page_url !== null) {
                        load(true);
                        axios
                          .get(users.prev_page_url + "&" + result[3], {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);

                            setUsers(res.data.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span class="material-icons">
                      <IoChevronBackCircle />
                    </span>
                  </li>
                  {liShows()}
                  <li
                    onClick={() => {
                      if (users.next_page_url !== null) {
                        load(true);
                        axios
                          .get(users.next_page_url + "&" + result[3], {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            setUsers(res.data.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span class="material-icons">
                      <IoArrowForwardCircleSharp />
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <Analyze  type={analyzeType} data={users} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Success
        show={modal}
        closeModal={() => {
          setModal(false);
        }}
        name="ihefjhfj"
      >
        {inModalShow()}
      </Success>
      <NotificationContainer />
      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              setTr("all");
              setAcc("all");
              load(true);
              axios
                .get(url + "api/v1/admin/reports/accounts", {
                  headers: { Authorization: `bearer ${Cookies.get("token")}` },
                })
                .then((res) => {
                  load(false);
                  console.log(res.data.data);
                  setActiver({ a1: "active", a2: "", a3: "" });
                  setUsers(res.data.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={activer.a1}
          >
            گزارش گیری
          </a>
          <a
            onClick={() => {
              setActiver({ a1: "", a2: "active", a3: "" });

              if (result !== "") {
                const newArray = [...result];
                newArray[3] = "";
                setResult(newArray);
              }
              setUsers({ data: [], links: [] });
              setTr("all");
              setAcc("all");
            }}
            className={activer.a2}
          >
            آنالیز
          </a>
        </div>
        {mainShow()}
      </div>
    </div>
  );
}
